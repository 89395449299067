.card {
  padding: 1rem;
  border: 1px solid #e0e0e0;
  border-radius: 0.5rem;
  max-width: 20rem;
  font-family: Arial, sans-serif;
  color: #333;
  font-size: 0.875rem;
  background-color: #fff;

  &__item {
    margin-bottom: 0.5rem;
    line-height: 1.25rem;
  }

  &__deadline {
    color: #888;
    font-size: 0.8125rem;
  }

  &__button {
    margin-top: 1rem;
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    border: 1px solid #1f4f51;
    border-radius: 0.25rem;
    background-color: #1f4f51;
    cursor: pointer;
    color: #FFFFFF;

    &:hover {
      background-color: #f5f5f5;
      color: #1a3b3d;
    }
  }
}
