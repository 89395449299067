.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5); /* Overlay background */
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  overflow: hidden;
  z-index: 1000;
}

.success-modal {
  background-color: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  text-align: center;

  .modal-content {
    .icon-wrapper {
      margin-bottom: 15px;

      .success-icon {
        font-size: 40px;
        color: #2f80ed;
      }
    }

    .message {
      font-size: 16px;
      color: #333;
      margin-bottom: 20px;
    }

    .close-btn {
      padding: 10px 20px;
      background-color: #2f80ed;
      color: white;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      font-size: 14px;
    }
  }
}
