.avatar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.avatar-image {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #ddd;
  margin-bottom: 10px;
}

.avatar-button {
  display: inline-block;
  padding: 8px 16px;
  color: #fff;
  background-color: #1F4F51;
  border-radius: 4px;
  cursor: pointer;
  margin: 1rem;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #1F4F51;
  }
}
