.sidebar {
  //   width: 17.5rem; /* 280px => 17.5rem */
  background-color: #204f51;
  color: white;
  //padding: 1.25rem;
  height: 85vh;
  overflow-y: auto;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .menuItem {
    margin-bottom: 1rem;

    .menuTitle {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0.5rem 0.5rem;
      //padding: 0.875rem 0rem;
      cursor: pointer;
      background-color: #204f51;
      border-radius: 1rem;
      transition: background-color 0.3s ease-in-out;

      &:hover {
        background-color: #1a3b3d;
      }

      &.active {
        background-color: #163032;
      }

      .icon {
        //margin-right: 1rem;
        font-size: 1.25rem; /* Icon o'lchami */
      }

      .arrow {
        margin-left: auto;
      }
    }

    .childMenu {
      padding-left: 1.5rem;
      margin-top: 0.5rem;
      opacity: 0;
      height: 0;
      overflow: hidden;
      transition: opacity 0.3s ease, height 0.3s ease;

      .childMenuItem {
        padding: 0.5rem .5rem;
        cursor: pointer;
        background-color: #163032;
        border-radius: 1rem;
        display: flex;
        align-items: center;
        transition: background-color 0.3s ease-in-out;

        &:hover {
          background-color: #204f51;
        }

        .icon {
          margin-right: 0.75rem;
          font-size: 1rem; /* Sub-menu icon o'lchami */
        }
      }
    }

    &.open .childMenu {
      opacity: 1;
      height: auto;
    }
  }
}
