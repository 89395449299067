.timepicker-container {
  background-color: white;
  border-radius: 12px;
  padding: 10px 15px;
  //   box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: inline-block;
  width: 13rem;

  .time-inputs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .time-select {
      position: relative;
      width: 48%;

      select {
        padding: 5px 10px;
        border-radius: 8px;
        border: none;
        appearance: none;
        outline: none;
      }

      &:after {
        content: '▼';
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
      }
    }

    span {
      padding: 0 10px;
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;

    button {
      padding: 8px 20px;
      border-radius: 8px;
      border: none;
      font-size: 14px;
      cursor: pointer;

      &.cancel-btn {
        background-color: #f0f0f0;
        color: #333;
        margin-right: 10px;
      }

      &.save-btn {
        background-color: #2f80ed;
        color: white;
      }
    }
  }
}
