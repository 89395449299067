.schedule-container {
  background-color: #fff;
  //   border: 1px solid #ddd;
  border-radius: 1rem;
  padding: 20px;
  //   box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  //   max-width: 400px;

  .schedule-header {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .schedule-subtitle {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
  }

  .time-slots {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: .5rem;
    margin-bottom: 20px;

    .time-slot {
      display: flex;
      align-items: center;
      justify-content: center;
      //padding: 10px;
      //border-radius: 5px;
      text-align: center;
      //border: 1px solid #ddd;
      cursor: pointer;

      input[type='checkbox'] {
        position: absolute;
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        width: 1px;
        height: 1px;
        margin: -1px;
        overflow: hidden;
        white-space: nowrap;
        border: 0;
      }

      span {
        font-size: .8rem;
        font-weight: 500;
        transition: .3s all;
        color: #BEBEBE;
      }

      input:checked + span {
        color: #08B84E;
      }

      &.not_scheduled {
        //background-color: #fbe344;
        border-color: #fff;

        span {
          color: #08B84E;
        }

        &:hover {
          span {
            color: #08B84E;
          }
        }

        input:not(:checked) + span {
          color: #FF4343;
        }
      }

      &.scheduled {
        //background-color: #e8f5e9;
        background-color: #FFFFFF;

        //color: #43a047;
        //border-color: #43a047;
        span {
          color: #fbe344 !important;
        }

        &:hover {
          //background-color: #c8e6c9;
          span {
            color: rgb(251, 152, 81);
          }
        }
      }

      &.unavailable {
        background-color: #f5f5f5;
        color: #999;
        cursor: not-allowed;

        input[type='checkbox'] {
          cursor: not-allowed;
        }
      }

      &.selected {
        background-color: #43a047;
        color: #fff;
      }
    }
  }

  .date-picker {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    &-input {
      width: 48%;

      border: 1px solid #ddd;
      border-radius: 5px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      input {
        padding: 8px;
        width: 85%;
        font-size: 14px;

        svg {
          width: 1rem;
          margin-right: 1rem;
        }
      }

      input[type='date']::-webkit-inner-spin-button,
      input[type='date']::-webkit-calendar-picker-indicator {
        display: none;
        -webkit-appearance: none;
      }
    }
  }

  .schedule-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .repeat-schedule {
    display: flex;
    align-items: center;
    //margin-bottom: 20px;

    input[type='checkbox'] {
      margin-right: 10px;
    }

    label {
      font-size: 14px;
      color: #666;
    }
  }

  .save-button {
    display: flex;
    justify-content: flex-end;

    button {
      padding: .5rem 1rem;
      background-color: #1F4F51;
      color: #fff;
      border: none;
      border-radius: 5px;
      font-size: 1rem;
      cursor: pointer;

      &:hover {
        background-color: #1F4F51;
      }
    }
  }
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
