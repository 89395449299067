@import url(./libs.scss);

//________NORMALIZE_____________

img,
legend {
  border: 0;
}
legend,
td,
th {
  padding: 0;
}
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
body {
  margin: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline: 0;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b,
optgroup,
strong {
  font-weight: 700;
}
dfn {
  font-style: italic;
}
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}
mark {
  background: #ff0;
  color: #000;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sup {
  top: -0.5em;
}
sub {
  bottom: -0.25em;
}
svg:not(:root) {
  overflow: hidden;
}
figure {
  margin: 1em 40px;
}
hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0;
}
pre,
textarea {
  overflow: auto;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}
button {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input {
  line-height: normal;
}
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto;
}
input[type='search'] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}
*,
::after,
::before {
  box-sizing: inherit;
}
.container {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 1200px) {
  .container {
    max-width: 1150px;
  }
}
@media (min-width: 1500px) {
  .container {
    max-width: 1330px;
  }
}
img {
  max-width: 100%;
  height: auto;
}
*,
::after,
::before {
  box-sizing: border-box;
}

input[type='date']::-webkit-inner-spin-button,
input[type='date']::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

input[type='date']::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;

  display: block;
  background: url('/assets/img/icons/calendarmini.svg') no-repeat;
  width: 20px;
  height: 20px;
  border-width: thin;
  position: absolute;
  right: 5px;
  z-index: 1000;
}

//___________COLORS____________

$blue: #527ae7;
$darkGreen: #1f4f51;
$gray: #83878f;
$black: #202125;
$pink: #ff1477;
$orange: #fb9851;
$darkBlue: #00347e;
$red: #ff1414;
$green: #09b74e;
$lightGreen: #9bc4ac;

//___________VARIABLES___________

//___________FONTS____________

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

//___________MIXINS____________

@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin centerInline {
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

@mixin spaced {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@mixin trans {
  transition: 0.6s all;
}

@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

@mixin absoluteCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin absoluteCenterX {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin absoluteCenterY {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin bgCover($item) {
  background: url($item) center center no-repeat;
  background-size: cover;
}

@mixin bgContain($item) {
  background: url($item) center center no-repeat;
  background-size: contain;
}

@mixin card {
  border-radius: 1rem;
  background: #fff;
}

@mixin cardBlue {
  border-radius: 1rem;
  background: #1f4f51;
  color: #fff;
}

.flex {
  &-end {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
  }
}

//___________COMMOM____________

:root {
  font-size: 30px;
}

::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-track {
  background: #ddd;
}

::-webkit-scrollbar-thumb {
  background: $darkGreen;
  border-radius: 0.4rem;
}

ul,
li {
  margin: 0;
  padding: 0;
  display: block;
}

input,
textarea,
.mx-input,
.vue__time-picker input.display-time {
  border: none;
  outline: none;
  border-radius: 0.5rem;
  color: $black;
  display: inline-flex;
  align-items: center;
  box-shadow: none;

  &::placeholder {
    color: $gray;
  }
}

textarea {
  resize: none;
}

a {
  transition: 0.3s all;
  outline: none;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
}

body {
  font-size: 0.8rem;
  font-family: 'Inter', sans-serif;
  background: #f5f6fa;
  color: $gray;
  padding: 2rem 3rem 2rem 28rem;

  &.admin-padding {
    padding-left: 25rem;
  }
}

.overflowH {
  overflow: hidden;
}

.btn {
  padding: 0.6rem 1.2rem;
  border-radius: 0.5rem;
  color: #fff;
  @include centerInline();
  font-size: 0.7rem;
  font-weight: 500;
  background: $darkGreen;
  border: none;
  @include trans();
  outline: none;
  font-weight: 600;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &-blue {
    background: $blue;
  }

  &-gray {
    background: #ccc;
  }

  &-white {
    background: #fff;
    color: $black;
  }

  &-lightblue {
    //background: rgba(89, 134, 255, 0.1);
    background: rgb(155, 196, 172);
    color: $darkGreen;
  }

  &-orange {
    background: $orange;
  }
  &-red {
    background-color: #cb323f;
  }

  &-transparent {
    color: $darkGreen;
    background: transparent;
  }

  &-trans {
    background: transparent;
    border: 1px solid $darkGreen;
    color: $darkGreen;

    &:hover {
      background: $lightGreen;
      border-color: $lightGreen;
    }
  }

  &-green {
    background: $green;
  }

  &-lightgreen {
    background: $lightGreen;
  }

  &-white {
    background: #fff;
    color: $black;
  }
}

.search {
  margin-right: 1.5rem;

  &-input {
    position: relative;
    display: flex;
    align-items: center;

    &__input {
      height: 2.5rem;
      width: 22rem;
      padding: 0 5rem 0 1.2rem;
    }

    svg {
      position: absolute;
      width: 1rem;
      height: 1rem;
      cursor: pointer;
      z-index: 2;
      color: $gray;

      &:hover {
        color: $darkGreen;
      }
    }

    &__ico {
      right: 1.2rem;
    }

    &__filter {
      right: 3.2rem;
    }
  }
}

.dropdown {
  position: relative;
  width: 9.5rem;
  font-size: 0.7rem;

  &__btn {
    background: #ffffff;
    box-shadow: 0px 0.5rem 1.5rem rgba(238, 238, 242, 0.7);
    border-radius: 0.5rem;
    width: 100%;
    display: inline-flex;
    align-items: center;
    padding-left: 1rem;
    height: 2.1rem;
    cursor: pointer;
    font-style: italic;

    &::after {
      position: absolute;
      content: '';
      width: 0.6rem;
      height: 0.6rem;
      right: 1rem;
      //error
      // background: url(../img/icons/chevron-down.svg) center center no-repeat;
      background-size: contain;
    }
  }

  &__list {
    width: 100%;
    border-radius: 0.5rem;
    background: #ffffff;
    padding: 1rem;
    position: absolute;
    margin-top: 0.5rem;

    li {
      margin-bottom: 0.5rem;
      cursor: pointer;

      &:hover {
        color: $darkGreen;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.arrows {
  display: flex;
  gap: 0.3rem;

  button {
    width: 1.6rem;
    height: 1.6rem;
    @include centerInline();
    background: #fff;
    border-radius: 50%;
    color: $black;
    cursor: pointer;
    user-select: none;

    svg {
      width: 1.2rem;
      height: 1.2rem;
    }

    &:hover {
      color: $darkGreen;
    }
  }

  .arrow-left {
    margin-right: 0.75rem;
  }
}

.pagination {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.2rem;

  .arrow {
    width: 2rem;
    height: 2rem;
    color: #ffffff;
    background-color: #1f4f51;
    border-radius: 50%;
    @include center();
    font-weight: 600;
    font-family: 'Inter';
    font-size: 1rem;
    cursor: pointer;
    transition: 0.3s all;

    svg {
      width: 1.2rem;
      height: 1.2rem;
    }

    &:hover {
      color: $darkGreen;
      background-color: #fff;
    }
  }

  span {
    margin-right: 1rem;
  }

  ul {
    display: flex;
    margin: 0 0.5rem;

    li {
      margin: 0 0.35rem;

      a {
        color: $black;

        &:hover {
          color: $darkGreen;
        }

        &.current {
          color: $darkGreen;
          font-weight: 700;
          pointer-events: none;
        }
      }
    }
  }
}

.mark {
  span {
    display: inline-flex;
    margin-right: 0.3rem;
    color: #d9d9d9;

    svg {
      width: 0.6rem;
    }

    &.active {
      color: #ffcc4a;
    }
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 4rem 0 2.5rem;

  li {
    margin-right: 1.6rem;
    position: relative;

    &::after {
      content: '';
      width: 0.8rem;
      height: 0.8rem;
      // error
      // background: url(../img/icons/arrow-right.svg) center center no-repeat;
      background-size: contain;
      position: absolute;
      top: 0.1rem;
      right: -1.2rem;
    }

    a {
      color: $gray;
      display: block;

      svg {
        display: block;
        width: 1.2rem;
      }

      &.active {
        color: $darkGreen;
      }
    }

    &:last-child {
      margin-right: 0;

      &::after {
        content: none;
      }
    }

    &:first-child {
      margin-right: 1rem;

      &::after {
        content: none;
      }
    }
  }
}

//_____________TABLE__________

.table {
  table {
    width: 100%;
    border-radius: 0.5rem;
    overflow: hidden;
    font-size: 0.8rem;
    font-weight: 500;

    .head {
      color: #fff;
      background: $lightGreen;

      td {
        padding: 1rem 1.5rem;
      }
    }

    td {
      padding: 1.5rem;
    }

    tr {
      background: #f5f5f5;
      color: $black;

      &:nth-child(2n) {
        background: #fff;
      }
    }

    .bold {
      font-weight: 600;
    }

    .green {
      color: $green;
    }

    .red {
      color: $red;
    }
  }
}

//___________LOGIN________

.login {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
  // errpr
  background: url(../assets/img/login.jpg) center center no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem 0 5rem;
  overflow: auto;

  &__logo {
    margin-bottom: 2rem;
    text-align: center;

    img {
      width: 7rem;
      display: inline-block;
    }
  }

  &-form {
    @include cardBlue();
    padding: 2.5rem 4.2rem;
    width: 31rem;

    &__centered {
      @include absoluteCenter();
    }

    &__title {
      font-size: 600px;
      font-size: 1.5rem;
      text-align: center;
      margin-bottom: 2rem;
    }

    &__item {
      margin-bottom: 1.25rem;
      font-weight: 500;

      .filter-gender input:checked + span {
        background: #fb9851;
      }
    }

    &__name {
      margin-bottom: 0.5rem;
    }

    &__input {
      position: relative;
      width: 100% !important;

      input {
        width: 100% !important;
        border-radius: 0.5rem !important;
        height: 2.2rem;
        font-size: 0.7rem;
        padding: 0 0.8rem;
        border: none !important;
      }

      ul {
        li {
          background-color: #fff !important;
          button: {
          }
        }
      }

      svg {
        @include absoluteCenterY();
        right: 0.7rem;
        width: 1.2rem;
        cursor: pointer;
      }
    }

    &__password,
    &__date {
      input {
        padding-right: 3rem;
      }
    }

    &__sms input {
      letter-spacing: 1.5rem;
    }

    &__advice {
      margin-top: 0.3rem;
      font-size: 0.6rem;
    }

    &__check {
      margin-bottom: 1rem;

      label {
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;
      }

      input {
        width: 1rem;
        height: 1rem;
        border: 50%;
        background: #fff;
        appearance: none;
        margin-right: 0.5rem;
        flex-shrink: 0;

        &:checked {
          background: #fb9851;
        }
      }

      span {
        font-size: 0.7rem;
      }
    }

    &__submit {
      margin-top: 2rem;

      .btn {
        width: 100%;
        height: 3rem;
      }
    }
  }
}

//____________SIDE________

.sidemenu {
  position: fixed;
  left: 0;
  top: 3rem;
  width: 5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 4;

  &__logo {
    width: 3.7rem;
    margin-bottom: 2.2rem;

    img {
      width: 100%;
      display: block;
    }
  }

  &__menu {
    position: relative;
    margin: 13.3rem;
    li {
      margin-bottom: 1rem;

      a {
        @include centerInline();
        position: relative;
        width: 2.6rem;
        height: 2.6rem;
        background: rgb(155, 196, 172);
        border-radius: 0.5rem;
        color: $gray;

        svg {
          width: 1.2rem;
          height: 1.2rem;
          path {
            fill: #ffffff;
          }
        }

        &:hover {
          color: $darkGreen;

          span {
            display: inline-block;
          }
        }

        &.active {
          color: $darkGreen;

          svg {
            path {
              fill: $darkGreen;
            }
          }

          .orange {
            color: $orange;
          }
        }

        span {
          position: absolute;
          z-index: 2;
          background: $darkGreen;
          color: #fff;
          left: 110%;
          padding: 0.5rem 0.8rem;
          border-radius: 0.5rem;
          display: none;
          pointer-events: none;

          &::before {
            @include absoluteCenterY();
            left: -0.4rem;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0.4rem 0.4rem 0.4rem 0;
            border-color: transparent $darkGreen transparent transparent;
            content: '';
          }
        }
      }
    }
  }
}

.sideinfo {
  position: fixed;
  top: 2rem;
  left: 5rem;
  width: 20.5rem;

  &-admin {
    left: 2.5rem;
  }
}

.sidebar {
  &-mobile {
    display: none;
  }

  &-photo {
    display: none;
  }
}

//___________PROFILE________

.profile {
  &-info {
    @include cardBlue();
    box-shadow: 0px 0.35rem 1rem rgba(82, 122, 231, 0.4);
    padding: 1.5rem;
    color: #fff;
    @include spaced();
    margin-bottom: 1.4rem;

    &__wrap {
      display: flex;
      align-items: center;
    }

    &__photo {
      width: 2.5rem;
      height: 2.5rem;
      flex-shrink: 0;
      margin-right: 0.5rem;
      border: 0.25rem solid rgba(255, 255, 255, 0.5);
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
      }
    }

    &__name {
      font-size: 1.2rem;
      line-height: 100%;

      span {
        font-size: 0.8rem;
        opacity: 0.7;
      }
    }

    &__balance {
      display: flex;
      align-items: center;

      svg {
        width: 1.5rem;
        margin-right: 0.4rem;
      }
    }

    &__admin {
      padding: 0.8rem 1rem;
      flex-direction: row-reverse;
    }
  }

  &-menu {
    &__card {
      @include card();
      padding: 0.7rem 0.5rem;
      margin-bottom: 1.5rem;

      &:first-child {
        margin-left: -3.8rem;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &__wrap {
      display: flex;
      align-items: center;

      svg {
        width: 1.2rem;
        height: 1.2rem;
        margin-right: 0.8rem;
      }
    }

    &__list {
      li {
        a {
          @include spaced();
          width: 100%;
          color: $gray;
          padding: 0.7rem;

          div {
            span {
              font-size: 1rem;
            }
          }

          &:hover {
            color: $darkGreen;
          }

          &.current {
            border-radius: 0.5rem;
            //background: rgba(82, 122, 231, 0.05);
            background: rgb(155, 196, 172);
            color: $darkGreen;
            font-weight: 600;

            .orange {
              color: $orange;
            }
          }
        }
      }
    }
  }

  &-card {
    // background: $lightGreen;
    padding: 1rem;
    font-size: 0.7rem;
    color: #fff;
    border-radius: 1rem;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */

    &__head {
      @include spaced();
      margin-bottom: 0.5rem;
    }

    &__name {
      font-size: 0.8rem;
      font-weight: 700;
      max-width: 10rem;
      //   overflow: hidden;
      //   white-space: nowrap;
      //   text-overflow: ellipsis;
    }

    &__count,
    &__date {
      display: flex;
      align-items: center;

      img {
        width: 1rem;
        margin-right: 0.3rem;
      }
    }

    &__date {
      margin-bottom: 1.4rem;
    }

    &__join {
      @include spaced();
    }

    .btn {
      border: none;
    }
  }
}

.mobile-menu {
  display: none;
}

//_________HEADER______

.header {
  @include spaced();
  margin-bottom: 2rem;

  &__name {
    font-weight: 600;
    color: $black;
    font-size: 1.4rem;
  }

  &-lang {
    position: relative;
    margin-right: 1rem;

    &:hover {
      .header-lang__list {
        display: block;
      }
    }

    &__btn {
      cursor: pointer;
      padding-bottom: 1rem;
      margin-bottom: -1rem;
      color: $black;

      img {
        width: 1.2rem;
      }

      &-wrap {
        box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.03);
        height: 2.5rem;
        width: 7.5rem;
        background: #fff;
        gap: 0.3rem;
        @include center();
        border-radius: 0.5rem;
      }
    }

    &__list {
      position: absolute;
      z-index: 2;
      top: 2.5rem;
      width: 7.5rem;
      padding: 1rem;
      display: none;
      border-radius: 0 0 0.5rem 0.5rem;
      box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.03);
      background: #fff;
      transition: 0.3s all;

      a {
        margin-bottom: 0.5rem;
        cursor: pointer;
        color: $black;
        display: block;
        font-size: 0.7rem;
        transition: 0.3s all;
        font-weight: 600;
        &:hover {
          color: #fb9851;
          text-decoration: underline;
        }

        &.current {
          position: relative;
          color: #fb9851;
          &::after {
            @include absoluteCenterY();
            content: '';
            right: 0;
            width: 0.25rem;
            height: 0.25rem;
            border-radius: 50%;
            background: #fb9851;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &-nav {
    display: flex;

    &__menu {
      display: flex;

      a {
        width: 2.5rem;
        height: 2.5rem;
        @include centerInline();
        margin-right: 1rem;
        border-radius: 0.5rem;
        background: #fff;
        color: $darkGreen;

        svg {
          width: 1.2rem;
          height: 1.2rem;
        }

        &:last-child {
          margin-right: 0;
        }

        &.nots {
          svg path:nth-child(2) {
            color: $pink;
          }
        }
      }
    }

    &__date {
      position: relative;

      span {
        bottom: -3rem;
        position: absolute;
        font-size: 0.9rem;
        background: #fff;
        height: 2.5rem;
        @include centerInline();
        width: 7rem;
        border-radius: 0.5rem;
        box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.03);
        pointer-events: none;
        display: none;
        z-index: 9;

        &::before {
          @include absoluteCenterX();
          display: inline-block;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 0.5rem 0.5rem 0.5rem;
          border-color: transparent transparent #fff transparent;
          content: '';
          top: -0.3rem;
        }
      }

      &:hover span {
        display: inline-flex;
      }
    }
  }
}

//____________HOME_________

.home {
  &-recent {
    &__head {
      margin-bottom: 0.75rem;
      @include spaced();
    }

    &__title {
      font-size: 1rem;
      color: $black;
    }

    &__wrap {
      display: flex;
      align-items: center;
      gap: 0.8rem;

      .btn {
        width: 9rem;
        height: 1.6rem;
      }
    }

    &__carousel {
      margin-bottom: 1.5rem;

      .lesson {
        display: flex;
        align-items: stretch;

        &__img {
          width: 7.7rem;
          height: 7.1rem;
          flex-shrink: 0;
          margin-right: 0.25rem;
        }

        &__wrap {
          flex-wrap: wrap;
          gap: 0.25rem;
        }

        &-info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
        }

        &__links {
          font-weight: 500;

          a {
            cursor: pointer;
            color: #c4c4c4;
          }

          .blue {
            font-weight: 600;
            color: $darkGreen;
            margin-left: 1rem;
          }
        }
      }
    }
  }

  &-welcome {
    @include card();
    padding: 2.5rem 3rem;
    position: relative;
    margin-bottom: 3rem;

    &__content {
      max-width: 60%;
    }

    &__img {
      width: 40%;
      height: calc(100% - 0.5rem);
      position: absolute;
      right: 0.25rem;
      top: 0.25rem;
      border-radius: 0.75rem;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
      }
    }

    &__title {
      font-size: 1.6rem;
      color: $black;
      margin-bottom: 0.75rem;

      strong {
        color: $darkGreen;
        font-weight: 700;
      }
    }

    &__text {
      margin-bottom: 2.2rem;
      font-size: .9rem;
    }

    &__links {
      a {
        color: $orange;
        font-weight: 500;
        font-size: 0.9rem;
        margin-right: 1rem;
      }

      .blue {
        color: $darkGreen;
        font-weight: 600;
      }
    }
  }

  &-recomended {
    .lesson {
      position: relative;

      &__name {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      &__time svg {
        margin-right: 0.2rem;
      }

      &__link {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
      }
    }
  }
}

//____________LESSON__________

.lesson {
  @include card();
  padding: 0.25rem;
  font-size: 0.7rem;
  position: relative;

  &__tag {
    color: #fff;
    background: $darkGreen;
    @include centerInline();
    padding: 0.25rem 0.8rem;
    border-radius: 2rem;
    font-weight: 500;
    position: absolute;
    font-size: 0.7rem;
    right: 0.25rem;
    top: 0.25rem;
  }

  &__img {
    border-radius: 0.75rem;
    overflow: hidden;
    height: 9rem;

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }

  &__name {
    font-weight: 600;
    color: $black;
    font-size: 0.8rem;
    line-height: 150%;
    margin-bottom: 0.3rem;
  }

  &-info {
    padding: 0.75rem;

    svg {
      width: 1.2rem;
      height: 1.2rem;
    }
  }

  &__wrap {
    @include spaced();
    margin-bottom: 1.25rem;
  }

  &__text {
    font-size: 0.8rem;
    line-height: 150%;
    margin-bottom: 1.5rem;
  }

  &__time {
    display: flex;
    align-items: center;

    svg {
      margin-right: 0.6rem;
    }
    span {
      font-size: .8rem;
    }

    div {
      margin-left: 1rem;
      color: $darkGreen;
      position: relative;

      &::before {
        content: '';
        @include absoluteCenterY();
        left: -0.5rem;
        width: 4px;
        height: 4px;
        background: $gray;
        border-radius: 50%;
      }
    }
  }

  &__btn {
    width: 100%;
    position: relative;
    z-index: 3;
    font-weight: 700;
  }

  &-enter {
    @include center();
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(4px);
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    display: none;

    &__content {
      @include card();
      padding: 2rem;
      width: 27rem;
    }

    &__name {
      font-weight: 600;
      font-size: 1rem;
      line-height: 150%;
      color: $black;
      margin-bottom: 0.5rem;
    }

    &__date {
      margin-bottom: 1.5rem;
    }

    &__advice {
      background: rgba(251, 152, 81, 0.1);
      border: 1px solid $orange;
      border-radius: 0.5rem;
      padding: 0.6rem 1.2rem;
      line-height: 150%;
      margin-bottom: 1.5rem;
      color: $orange;

      span {
        color: $darkGreen;
      }
    }

    &__text {
      font-size: 0.7rem;
      line-height: 150%;
      margin-bottom: 1.5rem;

      a {
        color: $gray;

        &:hover {
          color: $darkGreen;
        }
      }
    }

    &__btns {
      display: flex;
      gap: 0.5rem;
    }
  }
}

.lessons {
  &-list {
    .history-table {
      &__head {
        & > div {
          &:nth-child(1) {
            width: 13%;
          }

          &:nth-child(2) {
            width: 8%;
          }

          &:nth-child(3) {
            width: 19%;
          }

          &:nth-child(4) {
            width: 7%;
          }

          &:nth-child(5) {
            width: 16%;
          }

          &:nth-child(6) {
            width: 9%;
          }

          &:nth-child(7) {
            width: 28%;
          }
        }
      }

      &__row {
        & > div {
          &:nth-child(1) {
            width: 13%;
          }

          &:nth-child(2) {
            width: 8%;
          }

          &:nth-child(3) {
            width: 19%;
          }

          &:nth-child(4) {
            width: 7%;
          }

          &:nth-child(5) {
            width: 16%;
          }

          &:nth-child(6) {
            width: 9%;
          }

          &:nth-child(7) {
            width: 28%;
          }
        }
      }

      span {
        margin-right: 2rem;
      }

      .btn {
        margin-left: 1rem;
      }
    }
  }

  &-single {
    &__head {
      display: flex;
      margin-bottom: 5rem;
    }

    &__title {
      margin-right: 6rem;
      font-weight: 500;
      color: $black;
      font-size: 1.2rem;

      span {
        font-size: 1rem;
        margin-left: 1.3rem;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .history-table {
      &__head {
        align-items: flex-end;

        & > div {
          &:nth-child(1) {
            width: 5%;
            padding-left: 0.7rem;
          }

          &:nth-child(2) {
            width: 5%;
          }

          &:nth-child(3) {
            width: 15%;
          }

          &:nth-child(4) {
            width: 10%;
          }

          &:nth-child(5) {
            width: 10%;
          }

          &:nth-child(6) {
            width: 10%;
          }

          &:nth-child(7) {
            width: 10%;
          }

          &:nth-child(8) {
            width: 10%;
          }

          &:nth-child(9) {
            width: 25%;
          }
        }
      }

      &__row {
        & > div {
          &:nth-child(1) {
            width: 5%;
            padding-left: 0.7rem;
          }

          &:nth-child(2) {
            width: 5%;
          }

          &:nth-child(3) {
            width: 15%;
          }

          &:nth-child(4) {
            width: 10%;
          }

          &:nth-child(5) {
            width: 10%;
          }

          &:nth-child(6) {
            width: 10%;
          }

          &:nth-child(7) {
            width: 10%;
          }

          &:nth-child(8) {
            width: 10%;
          }

          &:nth-child(9) {
            width: 25%;
          }
        }
      }

      .btn {
        margin-left: 0.75rem;
      }
    }
  }

  &-plan {
    .classes-single {
      padding-top: 0;
    }

    .homework-main {
      padding-top: 0;
    }

    .homework-list__item .homework-list__wrap:nth-child(2) {
      width: 100%;
      font-weight: bold;

      li {
        margin-bottom: 0.5rem;
      }
    }
  }
}

//_____________MESSAGES_________

.messages {
  height: calc(100vh - 5rem);
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  &-wrap {
    padding: 0 7vw 0 8vw;
  }

  &-list {
    margin-bottom: 1.5rem;
    max-height: calc(100vh - 15rem);
    overflow: auto;
    padding: 0 1rem;

    li {
      @include card();
      padding: 1.6rem 5rem 2rem 1.6rem;
      margin-bottom: 1.5rem;
      width: 60%;

      &:last-child {
        margin-bottom: 0;
      }
    }
    .left {
      float: left;
      border-radius: 0rem 1.5rem 1.5rem 1.5rem;
      background-color: #9bc4ac;
      color: #ffffff;
    }
    .right {
      float: right;
      border-radius: 1.5rem 1.5rem 0px 1.5rem;
    }
  }

  &-form {
    display: flex;
    gap: 0.7rem;

    input {
      font-size: 0.7rem;
      padding: 0 0.8rem;
      width: 80%;
    }

    button {
      width: 20%;
    }
  }

  &-nots {
    &__item {
      @include card();
      margin-bottom: 0.75rem;
      padding: 1.25rem 1.5rem;
      display: flex;
      align-items: stretch;
      position: relative;

      &.new {
        margin-bottom: 3rem;
        margin-top: -2.25rem;

        &::before {
          width: 0.45rem;
          height: 0.45rem;
          background: $darkGreen;
          border-radius: 50%;
          content: '';
          position: absolute;
          left: 0.6rem;
          top: 0.6rem;
        }
      }

      &:first-child {
        margin-top: 0;
      }
    }

    &__wrap {
      width: 100%;
    }

    &__name {
      font-weight: 500;
      font-size: 1rem;
      margin-bottom: 0.75rem;
      color: $black;
    }

    &__text {
      padding-right: 2rem;
      color: $black;
      line-height: 150%;
      margin-bottom: 1.6rem;
    }

    &__time {
      @include spaced();
      font-size: 0.7rem;
    }

    &__img {
      height: 100%;
      width: 11rem;
      flex-shrink: 0;
      margin: -0.85rem -1.1rem -0.85rem 1.5rem;
      border-radius: 0.75rem;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
      }
    }
  }

  &.nots {
    height: auto;
    justify-content: flex-start;
  }
}

//____________HISTORY_________

.history {
  &-table {
    width: 100%;
    margin-bottom: 3rem;

    &__head {
      margin-bottom: 0.75rem;
      display: flex;

      & > div {
        width: 20%;

        &:first-child {
          width: 15%;
        }

        &:nth-child(4) {
          width: 27%;
        }

        &:nth-child(5) {
          width: 23%;
        }
      }
    }
    &__teacher {
      span {
        font-size: 0.8rem;
      }
    }
    &__date {
      font-size: 0.7rem;
    }
    &__work {
      font-size: 0.7rem;
    }
    &__homework {
      display: flex;
      gap: 0.5rem;
      span {
        a {
          display: inline-block;
          padding: 0.6rem;
          border-radius: 0.5rem;
          font-size: 0.7rem;
          white-space: nowrap;
        }

        &:first-child {
          a {
            border: 1px solid #1f4f51;
            color: #1f4f51;
          }
        }
        &:last-child {
          a {
            background-color: #9bc4ac;
            color: #ffffff;
          }
        }
      }
    }

    &__row {
      color: $black;
      margin-bottom: 0.75rem;
      display: flex;
      @include card();
      padding: 1.6rem 0;
      align-items: center;
      position: relative;
      font-weight: 500;
      background: #e7eeef;

      &:hover {
        border-color: $darkGreen;

        .course-type {
          color: $darkBlue;
        }
      }

      & > div {
        width: 20%;
        padding-right: 1rem;

        &:first-child {
          width: 15%;
          padding-left: 1.2rem;
        }

        &:nth-child(4) {
          width: 27%;
        }

        &:nth-child(5) {
          width: 30%;
        }
      }
    }

    &__count {
      div {
        width: 2.8rem;
        height: 2.8rem;
        @include centerInline();
        background: $lightGreen;
        color: #fff;
        font-size: 0.7rem;
        border-radius: 50%;
      }
    }

    &__date {
      position: relative;

      span {
        color: $gray;
        font-weight: 400;
        display: block;
        margin-top: 0.3rem;
        font-size: 0.7rem;
      }
    }

    &__date small {
      position: absolute;
      top: -0.5rem;
      right: 1rem;
      color: #fff;
      background: $darkGreen;
      padding: 0.1rem 0.3rem;
      border-radius: 0.5rem;
      font-size: 0.7rem;
    }

    &__teacher {
      display: flex;
      align-items: center;

      img {
        flex-shrink: 0;
        margin-right: 0.6rem;
        width: 1.7rem;
        height: 1.7rem;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    &__homework {
      .empty {
        color: $pink;
      }
    }

    &__waiting {
      font-size: 0.7rem;
      color: $orange;
    }

    &__link {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }

    &__type {
      color: $gray;
    }

    &__btns {
      display: flex;

      .btn:last-child {
        flex-shrink: 0;
      }
    }
  }

  &-single {
    color: $black;
    padding-top: 2rem;

    &__name {
      font-size: 1.2rem;
      font-weight: 500;
      margin-bottom: 3rem;
    }

    &__item {
      margin-bottom: 6rem;

      &:last-child {
        margin-bottom: 4rem;
      }

      &.big .history-single__text textarea {
        height: 12rem;
      }
    }

    &__title {
      font-size: 1rem;
      margin-bottom: 1.2rem;
      font-weight: 500;
    }

    &__text textarea {
      width: 100%;
      padding: 1rem;
      height: 3rem;
    }
  }
}

//_____________REVIEWS_________

.reviews {
  &-list {
    padding-top: 2rem;

    &__item {
      @include spaced();
      @include card();
      background: #e7eeef;
      padding: 1.5rem;
      font-weight: 500;
      color: $black;
      margin-bottom: 0.75rem;
      position: relative;

      .course-type {
        color: $darkGreen;
        margin-right: 1.7rem;
        position: relative;

        &::after {
          content: '';
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: #d9d9d9;
          position: absolute;
          right: -1rem;
        }
      }
      &.click {
        &__on {
          border: 1px solid #1f4f51;
        }
      }
    }

    &__wrap {
      display: flex;
      align-items: center;
      font-size: .9rem;
    }

    &__link {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
    }

    &__teacher {
      display: flex;
      align-items: center;
      padding-right: 2rem;
      position: relative;

      img {
        width: 1.7rem;
        height: 1.7rem;
        border-radius: 50%;
        object-fit: cover;
        margin-left: 1.25rem;
      }

      span {
        position: absolute;
        right: 0;
        top: -0.2rem;
        @include centerInline();
        height: 0.9rem;
        border-radius: 0.9rem;
        background: $red;
        color: #fff;
        font-weight: 400;
        font-size: 0.7rem;
        padding: 0 0.35rem;
      }
    }
  }

  &-single {
    padding-top: 2rem;

    &__head {
      display: flex;
      align-items: center;
      margin-bottom: 4rem;
      font-weight: 500;
    }

    &__back {
      margin-right: 1.2rem;
      color: $black;

      &:hover {
        color: $darkGreen;
      }

      svg {
        width: 1.2rem;
      }
    }

    &__name {
      font-size: 1rem;
      color: $black;
      margin-right: 2.3rem;
    }

    .course-type {
      color: $darkGreen;
    }

    &__list {
      li {
        margin-bottom: 1.25rem;

        & > img {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 50%;
          object-fit: cover;
          display: block;
          border: 0.15rem solid #fff;
          margin-bottom: -1.25rem;
        }

        p {
          a {
            color: $black;

            &:hover {
              color: $darkGreen;
            }
          }
        }
      }
    }

    &__main {
      padding: 1.6rem 2.1rem;
      @include card();
      margin-left: 1.3rem;
      color: $black;
      line-height: 190%;
    }

    &__bot {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 1rem;
      gap: 1.5rem;
      font-size: 0.7rem;

      .btn {
        //width: 8rem;
        width: fit-content;
        height: 2rem;
      }
    }

    &__date {
      display: flex;
      align-items: center;
      gap: 0.6rem;
      color: #83878f;

      img {
        width: 1.2rem;
      }
    }
  }

  &-add {
    .reviews-single__head {
      margin-bottom: 1.7rem;
    }

    &__title {
      color: $black;
      font-size: 1rem;
      margin-bottom: 1.3rem;
    }

    &__info {
      max-width: 36rem;
      padding-bottom: 0.9rem;
      border-bottom: 1px solid $darkGreen;
      color: $black;
      margin-bottom: 1.4rem;

      li {
        margin-bottom: 0.6rem;

        span:first-child {
          font-weight: 600;
          color: $darkGreen;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__choose {
      display: flex;
      gap: 0.75rem;
      margin-bottom: 1.8rem;
    }

    &__item {
      width: 6.6rem;
      cursor: pointer;
      user-select: none;

      input {
        display: none;

        &:checked + .reviews-add__item-wrap {
          border-color: $lightGreen;
          box-shadow: 0px 2px 6.5px 1px rgba(155, 196, 172, 0.5);
        }
      }

      &-wrap {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.2rem;
        border: 1px solid #fff;
        @include trans();
        padding: 0.6rem;
        background: #fff;
        border-radius: 0.25rem;
        font-weight: 500;
        text-align: center;
      }

      &-name {
        height: 2rem;
        display: flex;
        align-items: center;
      }

      .mark {
        span {
          margin-right: 0;
        }

        gap: 0.1rem;
      }
    }

    &__textarea {
      margin-bottom: 2rem;

      textarea {
        border-radius: 1rem;
        padding: 1rem;
        height: 12rem;
        width: 100%;
      }
    }

    &__btn {
      text-align: center;

      .btn {
        text-transform: uppercase;
        width: 7rem;
      }
    }
  }
}

//____________COURSES_______

.course {
  &-type {
    display: flex;
    align-items: center;
    color: $darkGreen;

    &.orange {
      color: $orange;

      .course-type__ico {
        background: #ffeedb;
        color: $orange;
      }
    }

    &__ico {
      flex-shrink: 0;
      //width: 1.7rem;
      //height: 1.7rem;
      //background: $lightGreen;
      //color: $darkGreen;
      @include centerInline();
      border-radius: 50%;
      margin-right: 0.6rem;

      svg,
      img {
        min-width: 1.9rem;
        max-width: 2rem;
        height: 2rem;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    span {
      p {
        display: block;
        margin-top: 0.1rem;
        font-size: 0.7rem;
        font-weight: 400;
        color: $gray;
      }
    }
  }

  &__head {
    width: 5.3rem;
    height: 5.3rem;
    border-radius: 50%;
    position: relative;
    margin-bottom: 1.2rem;
    @include centerInline();
    //background: #dceeff;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }

    &::after {
      position: absolute;
      right: 0.3rem;
      bottom: 0.3rem;
      content: '';
      width: 1rem;
      height: 1rem;
      // error
      // background: url(../img/icons/star-white.svg) center center no-repeat;
      background-size: contain;
    }
  }

  &__name {
    font-size: 1.2rem;
    color: $darkGreen;
    font-weight: 500;
    margin-bottom: 2.4rem;
  }

  &-info {
    font-weight: 500;
    margin-bottom: 1.2rem;

    &__item {
      display: flex;

      span {
        width: 6rem;

        &:first-child {
          text-align: right;
          margin-right: 0.65rem;
        }

        &:nth-child(2) {
          color: $black;
        }
      }
    }
  }

  &__desc {
    max-width: 17rem;
    line-height: 200%;
    text-align: center;
    margin-bottom: 1.2rem;
  }

  &-numbers {
    display: flex;
    justify-content: space-between;
    text-align: center;
    width: 85%;
    margin-bottom: 2.1rem;

    &__ico {
      margin-bottom: 0.3rem;

      img {
        width: 1.6rem;
        display: inline-block;
      }
    }

    &__name {
      font-weight: 500;
      color: $black;
      margin-bottom: 0.3rem;
    }
  }

  &__btn {
    font-weight: 700;
    width: 9rem;
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;
    padding-top: 2rem;

    .course__head::after {
      content: none;
    }
  }

  &-item {
    width: calc(33.333% - 0.833333rem);
    @include card;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1.6rem;
    font-size: 0.9rem;

    .mark {
      margin-bottom: 1.2rem;

      span {
        margin-right: 0.4rem;

        svg {
          width: 0.7rem;
          height: 0.7rem;
        }
      }
    }
  }
}

.courses {
  &-single {
    &__head {
      padding-top: 2rem;
    }

    &__title {
      font-weight: 600;
      font-size: 1.6rem;
      color: $darkGreen;
      margin-bottom: 1.6rem;
    }

    &__text {
      margin-bottom: 2.5rem;
    }

    &__img {
      margin-bottom: 3rem;

      img {
        display: block;
        width: 100%;
        height: 20rem;
        border-radius: 1.5rem;
        object-fit: cover;
      }
    }

    &__wrap {
      display: flex;
      gap: 3rem;
    }

    &__content {
      width: 65%;
      flex-shrink: 0;
    }

    &__subtitle {
      font-size: 1.2rem;
      font-weight: 500;
      margin-bottom: 1rem;
      color: $black;
    }

    &__text {
      line-height: 150%;
      color: $black;
      margin-bottom: 2.5rem;
    }

    &__list {
      padding-left: 1rem;
      color: $black;
      margin-bottom: 2.5rem;

      li {
        display: list-item;
      }
    }

    &__btn {
      text-align: center;
      margin-bottom: 3rem;

      .btn {
        width: 13rem;
        text-transform: uppercase;
      }
    }

    &__more {
      .course-list {
        padding-top: 0;
      }
    }
  }

  &-cards {
    display: flex;
    gap: 1.2rem;
    margin-bottom: 3rem;
  }

  &-card {
    width: 33.33%;
    @include card();
    overflow: hidden;
    font-weight: 500;

    &__head {
      color: #fff;
      background: $lightGreen;
      text-align: center;
      padding: 1.2rem 0 0.5rem;
    }

    &__name {
      font-size: 1.2rem;
      margin-bottom: 0.7rem;
    }

    &__text {
      padding: 0.7rem 2rem 1.25rem;
      color: $black;
      line-height: 190%;
    }
  }

  &-group {
    &__filter {
      padding-top: 2rem;
      @include spaced();
      margin-bottom: 3rem;
    }

    &__wrap {
      display: flex;
      align-items: center;
    }

    &__title {
      font-size: 1.2rem;
      margin-right: 1.2rem;
      color: $black;
    }

    &__subtitle {
      font-weight: 600;
      color: $black;
      margin-right: 1.6rem;
    }

    &__item {
      @include card();
      display: flex;
      font-size: 0.7rem;
      margin-bottom: 0.75rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__img {
      padding: 0.4rem;
      flex-shrink: 0;
      width: 12rem;
      margin-right: 1.2rem;

      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        border-radius: 0.6rem;
      }
    }

    &__main {
      padding: 1.6rem 1.6rem 1.6rem 0;
      border-right: 0.2rem solid #f5f6fa;
      width: 100%;
    }

    &__right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 15.5rem;
      flex-shrink: 0;
      padding: 1.6rem 1.2rem;

      .courses-group__info li span:first-child {
        width: 70%;
      }
    }

    &__name {
      font-size: 1rem;
      font-weight: 500;
      color: $darkGreen;
      margin-bottom: 1rem;
    }

    &__info {
      margin-bottom: 1.2rem;
      font-size: 0.9rem;
      li {
        display: flex;
        margin-bottom: 0.4rem;

        &:last-child {
          margin-bottom: 0;
        }

        span {
          &:first-child {
            width: 4.5rem;
          }

          &:nth-child(2) {
            color: $black;
            font-weight: 500;
          }
        }
      }
    }

    &__desc {
      line-height: 200%;
      font-size: 0.9rem;
    }

    &__mark {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;

      & > span {
        margin-left: 0.4rem;
        border-bottom: 1px solid $gray;
      }
    }

    &__price {
      font-weight: 600;
      color: $darkGreen;
      font-size: 1rem;
      display: flex;
      white-space: nowrap;
      align-items: center;
      margin-bottom: 0.8rem;

      span {
        font-size: 0.7rem;
        font-weight: 500;
        display: flex;
        align-items: center;
        //margin-left: 0.4rem;

        img {
          width: 0.8rem;
          margin-right: 0.2rem;
        }
      }
    }

    &__btn {
      width: 11.5rem;
      &:hover {
        background-color: #1f4f51;
        color: #ffffff;
      }
    }

    &-single {
      &__card {
        @include card();
        margin-bottom: 3rem;
        display: flex;
      }

      &__wrap {
        padding: 2.5rem 3rem;
        width: 50%;
      }
      &__container {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 1rem;
      }

      &__img {
        width: 50%;
        padding: 0.25rem;
        position: relative;

        &::after {
          position: absolute;
          content: '';
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: linear-gradient(90.19deg, #ffffff 0.16%, rgba(252, 252, 252, 0) 98.92%);
        }

        img {
          width: 100%;
          height: 100%;
          display: block;
          border-radius: 0.75rem;
          object-fit: cover;
        }
      }

      &__title {
        margin-bottom: 1rem;
        font-size: 1.6rem;
        color: $darkGreen;
        font-weight: 500;
      }

      &__text {
        margin-bottom: 1rem;
      }

      &__numbers {
        display: flex;
        margin-bottom: 1.25rem;

        li {
          background: $lightGreen;
          border-radius: 0.5rem;
          margin-right: 0.75rem;
          font-size: 0.7rem;
          color: $black;
          padding: 0.6rem 0.8rem;
          @include centerInline();
          flex-direction: column;
          font-weight: 500;

          &:last-child {
            margin-right: 0;
          }

          span {
            color: #fff;
            font-size: 0.6rem;
            margin-top: 0.3rem;
            font-weight: 400;
          }
        }
      }

      &__list {
        margin-bottom: 1.6rem;
        li {
          margin-bottom: 0.6rem;
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: 0.9rem;

          span {
            &:first-child {
              width: 7.5rem;
            }

            &:nth-child(2) {
              color: $black;
            }
          }
        }
      }

      &__link {
        font-size: 0.7rem;
        margin-left: 1.2rem;
        color: #0074ff;
        border-bottom: 1px solid transparent;

        &:hover {
          border-color: #0074ff;
        }
      }

      &__text {
        font-size: 0.9rem;
        margin-top: 2.5rem;
        line-height: 120%;
      }

      &__btn .btn {
        color: $darkGreen;
      }
    }
  }

  &-new {
    padding: 2rem 0 7rem;

    &__title {
      font-size: 1.2rem;
      margin-bottom: 2.1rem;
      color: $black;
    }

    &__text {
      font-size: 0.7rem;
      line-height: 200%;
      margin-bottom: 3rem;
    }

    &__subtitle {
      color: $black;
      margin-bottom: 1.2rem;
    }

    &__table {
      margin-bottom: 4rem;
    }

    &__checks {
      margin-bottom: 2rem;

      input {
        display: none;

        &:checked + span {
          background: $darkGreen;
          color: #fff;
        }
      }

      label {
        font-size: 0.8rem;
        margin-right: 0.8rem;
        margin-bottom: 1rem;
        display: inline-block;
        user-select: none;
      }

      span {
        border-radius: 0.5rem;
        background: #fff;
        padding: 0.5rem 1.2rem;
        @include centerInline();
        cursor: pointer;
      }
    }

    &__buttons {
      .btn {
        width: 11rem;
      }
    }
  }

  &-waiting {
    padding-top: 3rem;
    margin-bottom: 3rem;

    .history-table {
      &__head {
        & > div {
          width: 19%;

          &:first-child {
            width: 15%;
          }

          &:nth-child(2) {
            width: 28%;
          }
        }
      }

      &__row {
        & > div {
          width: 19%;

          &:first-child {
            width: 15%;
          }

          &:nth-child(2) {
            width: 28%;
          }
        }
      }
    }
  }

  &-today {
    .history-table {
      &__head {
        & > div {
          width: 20%;

          &:first-child {
            width: 9%;
          }

          &:nth-child(2) {
            width: 15%;
          }

          &:nth-child(5) {
            width: 15%;
          }

          &:nth-child(6) {
            width: 21%;
          }
        }
      }

      &__row {
        padding: 1.2rem 0;

        & > div {
          width: 20%;

          &:first-child {
            width: 9%;
          }

          &:nth-child(2) {
            width: 15%;
          }

          &:nth-child(5) {
            width: 15%;
          }

          &:nth-child(6) {
            width: 21%;
          }
        }
      }
    }
  }
}

//____________FILTER________

.filter {
  padding-top: 3rem;
  margin-bottom: 2rem;

  label {
    user-select: none;
  }

  &-search {
    width: 15rem;

    .search-input {
      width: 100%;

      &__input {
        width: 100%;
      }
    }
  }

  &-title {
    font-weight: 500;
    color: $black;
    margin-bottom: 0.25rem;
  }

  &-wrap {
    display: flex;
    align-items: center;

    input,
    select {
      width: 8.5rem;
      height: 2.1rem;
      padding: 0 0.7rem;
      margin-right: 0.75rem;
      border: none;
      border-radius: 0.5rem;

      &:last-child {
        margin-right: 0;
      }

      &::placeholder {
        font-style: italic;
        font-size: 0.7rem;
      }
    }
  }

  &-gender {
    input {
      display: none;

      &:checked + .btn {
        background: $darkGreen;
        color: #fff;
      }
    }

    label {
      margin-right: 0.9rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-time {
    position: relative;

    ::-webkit-scrollbar {
      width: 0.2rem;
    }
  }

  &-days {
    .filter-wrap {
      background: $lightGreen;
      font-weight: 600;
      border-radius: 0.5rem;
      padding: 0 0.5rem;
    }

    input {
      display: none;

      &:checked + span {
        color: #fff;
        background: $darkGreen;
      }
    }

    span {
      padding: 0.6rem;
      cursor: pointer;
      @include centerInline();
      color: $black;
    }
  }

  &-courses {
    .filter-wrap {
      flex-wrap: wrap;
    }

    input {
      display: none;

      &:checked + span {
        color: #fff;
        background: $darkGreen;
      }
    }

    label {
      margin-right: 0.35rem;
      margin-bottom: 0.9rem;
    }

    span {
      padding: 0.3rem 0.5rem;
      @include centerInline();
      border-radius: 2rem;
      font-size: 0.8rem;
      background: #fff;
      cursor: pointer;
    }
  }

  &__head {
    @include spaced();
    margin-bottom: 1.5rem;
  }

  &__wrap {
    display: flex;
    align-items: center;
  }

  &__title {
    font-size: 1.2rem;
    color: $black;
    margin-right: 1.2rem;
  }

  &__main {
    display: flex;
    margin-bottom: 1.2rem;

    & > div {
      margin-right: 1.6rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-nodata {
    display: block;
    position: relative;
    max-width: 60%;
    height: auto;
    object-fit: cover;
    margin: 0 auto;
  }
}

//_____________TEACHERS_________

.teachers {
  &-list {
    margin-bottom: 3rem;
  }

  &-item {
    margin-bottom: 0.8rem;
    padding: 1.2rem 2.5rem;
    @include card();
    display: flex;
    align-items: flex-start;
    gap: 3rem;

    &:last-child {
      margin-bottom: 0;
    }

    &__wrap {
      &:first-child {
        width: 9rem;
        flex-shrink: 0;
      }

      &:nth-child(2) {
        width: 50%;
      }

      &:nth-child(3) {
        max-width: 50%;
      }
    }

    &__img {
      width: 100%;
      margin-bottom: 1rem;
      position: relative;

      &::after {
        position: absolute;
        bottom: 0.25rem;
        right: 0.25rem;
        width: 1.8rem;
        height: 1.8rem;
        content: '';
        // error
        // background: url(./assets/img/icons/play.svg) center center no-repeat;
        background-size: contain;
      }

      img {
        width: 100%;
        height: 11rem;
        object-fit: cover;
        display: block;
        border-radius: 0.5rem;
      }
    }

    &__btn {
      width: 100%;
      color: $darkGreen;
    }

    .mark {
      margin-top: 0.35rem;
    }

    &__name {
      font-weight: 500;
      font-size: 1rem;
      margin-bottom: 0.8rem;
      color: $black;
    }

    &__pos {
      font-size: 0.9rem;
      margin-bottom: 1.2rem;
    }

    &__desc {
      color: $black;
      font-weight: 300;
      font-size: 0.9rem;
      line-height: 200%;
      margin-bottom: 1.6rem;
    }

    &__courses {
      display: flex;
      gap: 0.8rem;
      font-weight: 500;
      font-size: 0.7rem;
      color: $darkBlue;
      flex-wrap: wrap;

      .course-type__ico {
        display: block;
        width: 1.5rem;
        height: 1.5rem;

        svg,
        img {
          display: block;
          width: 0.5rem;
          height: auto;
        }
      }
    }
  }

  &-single {
    &__wrap {
      padding-top: 3rem;
      display: flex;
      align-items: flex-start;
    }
  }

  &-courses {
    width: 100%;

    &__title {
      margin-bottom: 1.6rem;
      font-size: 1.2rem;
      color: $black;
    }
  }

  &-course {
    margin-bottom: 1.2rem;
    @include card();
    padding: 1.6rem 0;
    display: flex;

    &:last-child {
      margin-bottom: 0;
    }

    &__info {
      padding: 0 2.1rem;
      width: 48%;
      border-right: 0.1rem solid #f5f6fa;
    }

    &__calendar {
      width: 52%;
      padding: 0 2.5rem;
    }

    .mark {
      margin-bottom: 1.2rem;

      span {
        margin-right: 0.4rem;

        svg {
          width: 0.7rem;
          height: 0.7rem;
        }
      }
    }

    &__text {
      font-size: 0.7rem;
      line-height: 200%;
      margin-bottom: 1.2rem;
    }

    &__link {
      width: 7rem;
    }
  }

  &-schedule {
    padding: 1.6rem 1rem;
    @include card();
    font-weight: 500;
    width: 23rem;

    &__day {
      font-size: 0.7rem;
      color: $black;
      margin-bottom: 0.3rem;
    }

    &__desc {
      font-size: 0.6rem;
      margin-bottom: 0.5rem;
    }

    &__list {
      position: relative;
      margin: 0 -0.75rem;
      max-height: 15rem;
      padding-right: 0.5rem;
      overflow-y: auto;

      li {
        padding: 0.65rem 0.75rem;
        margin-bottom: 0.2rem;
        @include spaced();
        font-size: 0.7rem;
        border-radius: 0.5rem;

        &:hover {
          background: rgba(0, 0, 0, 0.02);
        }

        &:last-child {
          margin-bottom: 0;
        }

        span {
          &:first-child {
            width: 5rem;
          }

          &:last-child {
            color: $orange;
          }
        }
      }

      .available {
        color: $green;
      }

      .unavailable {
        color: $red;
      }
    }
  }

  &-info {
    width: 17.5rem;
    flex-shrink: 0;
    margin-left: 1.7rem;
    @include card();
    padding: 0.25rem;

    .teachers-item__img img {
      height: 12.5rem;
    }

    .mark {
      margin-bottom: 1rem;
    }

    .teachers-item__pos {
      margin-bottom: 0.7rem;
    }

    &__wrap {
      padding: 0 1.3rem 1.3rem;
    }

    &__title {
      margin-bottom: 0.5rem;
      font-weight: 600;
      color: $black;
    }

    &__text {
      font-size: 0.7rem;
      line-height: 200%;
      margin-bottom: 1.6rem;
      color: $black;
    }
  }
}

//_____________INDIVIDUAL__________

.individual {
  &-wrap {
    display: flex;
    padding-top: 3rem;
    align-items: flex-start;
    .filter {
      padding-top: 0;
      width: 16.5rem;
      flex-shrink: 0;
      margin-right: 1.25rem;

      &__head {
        display: block;
      }

      &__wrap {
        display: block;
        margin-bottom: 1.25rem;
      }

      &__title {
        margin-bottom: 1.25rem;
      }

      &-search {
        width: 100%;
      }

      &-gender {
        display: flex;
        justify-content: space-between;
        gap: 0.75rem;

        label {
          margin-right: 0;
          width: 50%;

          span {
            width: 100%;
          }
        }
      }

      &__main {
        flex-direction: column;

        & > div {
          margin-right: 0;
        }
      }

      &-date,
      &-time {
        margin-bottom: 1.25rem;

        .filter-wrap {
          gap: 1rem;

          input,
          select {
            width: 50%;
            margin-right: 0;
            border: none;
            padding: 0.5rem;
            border-radius: 0.4rem;
          }
        }
      }
    }
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  &-item {
    //min-width: calc(33.333% - 0.666666rem);
    width: 100%;
    max-width: calc(33.333% - 0.666666rem);
    @include card();
    padding: 1.2rem;

    &__head {
      display: flex;
      align-items: center;
      margin-bottom: 1.6rem;
    }

    &__ico {
      width: 3rem;
      height: 3rem;
      margin-right: 0.6rem;
      border-radius: 50%;
      background: #dceeff;
      flex-shrink: 0;
      @include centerInline();
      color: $darkGreen;

      svg {
        width: 1.6rem;
        height: 1.6rem;
      }
    }

    &__name {
      color: $darkGreen;
      font-weight: 600;
      margin-bottom: 0.4rem;
    }

    &__price {
      display: flex;
      align-items: center;
      font-size: 0.7rem;
      color: $black;

      img {
        width: 0.9rem;
        margin-right: 0.3rem;
      }
    }

    &__date,
    &__time {
      margin-bottom: 0.6rem;
      display: flex;
      align-items: center;

      img {
        width: 1.2rem;
        margin-right: 0.6rem;
      }
    }

    &__time {
      margin-bottom: 2rem;
    }

    &__user {
      display: flex;
      align-items: center;
      color: $black;
      margin-bottom: 1.5rem;

      img {
        display: block;
        width: 100%;
        min-width: 1.6rem;
        max-width: 1.7rem;
        height: 1.6rem;
        border-radius: 50%;
        margin-right: 0.8rem;
        object-fit: cover;
      }
    }

    &__btn {
      text-align: center;

      .btn {
        color: white;
        width: 7rem;
      }
    }
  }

  &-mask {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    background-color: #fff;
    box-shadow: 0px 15px 50px 0px rgba(0, 0, 0, 0.08);
    border-radius: 1rem;
    padding: 2rem;
  }

  &-popup {
    position: relative;
    // left: 0;
    // top: 0;
    width: 100%;
    height: 100%;
    // z-index: 1000;
    // background: rgba(0, 0, 0, 0.2);
    // backdrop-filter: blur(4px);
    @include center();
    display: none;

    &__content {
      @include card();
      //padding: 2.1rem;
      width: 27rem;
    }

    &__title {
      font-size: 1rem;
      font-weight: 600;
      margin-bottom: 1.5rem;
      color: $black;
    }

    &__text {
      font-size: 0.7rem;
      line-height: 150%;
      margin-bottom: 1.5rem;
    }

    .btn-lightgreen {
      margin-right: 0.5rem;
    }

    &__done {
      text-align: center;

      .individual-popup__title {
        margin-bottom: 0.8rem;
      }
    }

    &__img {
      margin-bottom: 0.5rem;

      img {
        width: 7.5rem;
        display: inline-block;
      }
    }

    &__btns {
      display: flex;
      gap: 1.2rem;
      button {
        width: 100%;
      }
    }
  }
}

//__________SUPPORT_________

.page-title {
  font-weight: 500;
  font-size: 1.6rem;
  margin-bottom: 1.25rem;
  color: $darkGreen;
}

.support {
  &-content {
    margin-bottom: 3.5rem;
  }

  &__text {
    margin-bottom: 2rem;

    p {
      margin-bottom: 0.75rem;
      color: $black;

      &.gray {
        color: $gray;
      }
    }

    a {
      color: $darkGreen;
    }
  }

  &__list {
    padding-left: 1rem;

    li {
      margin-bottom: 0.75rem;
      display: list-item;

      a {
        color: $darkGreen;
      }
    }
  }

  &__title {
    font-size: 1.2rem;
    font-weight: 500;
    color: $black;
    margin-bottom: 1rem;
  }

  &__subtitle {
    font-size: 1rem;
    color: $darkGreen;
    margin-bottom: 1rem;
    font-weight: 400;
  }

  &-quest {
    margin-bottom: 2.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    &.active {
      .support {
        &-quest {
          &__answer {
            background-color: #1f4f51;
            color: #ffffff;
            span {
              svg {
                path {
                  stroke: #ffffff;
                }
              }
            }
          }
        }
      }
    }

    &__answer {
      cursor: pointer;
      display: flex;
      @include spaced();
      color: $black;
      font-weight: 500;
      font-size: 1.2rem;
      user-select: none;
      background-color: #ffffff;
      padding: 1rem 2rem;
      border-radius: 1rem;
      transition: 0.3s all;
      span {
        svg {
          path {
            transition: 0.3s all;
          }
        }
      }
    }

    &__quest {
      margin-top: 1.25rem;
      padding-left: 2.5rem;
      display: none;
    }

    &__title {
      color: $darkGreen;
      font-weight: 500;
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
    }

    &__text {
      font-size: 0.7rem;
      color: $black;
      margin-bottom: 1.25rem;
      max-width: 28.5rem;

      a {
        color: $darkGreen;
      }

      h4 {
        margin-bottom: 0.5rem;
      }
    }

    &.active {
      .support-quest__quest {
        display: block;
      }

      .support-quest__answer svg {
        transform: rotate(180deg);
      }
    }
  }
}

.manual {
  &-list {
    max-width: 45rem;
  }
}

//___________SETTINGS_________

.settings {
  &-form {
    padding-top: 3rem;

    &__item {
      margin-bottom: 3.5rem;
      width: 67%;

      &-full {
        width: 100%;
      }
    }
  }

  &-title {
    font-size: 1.2rem;
    font-weight: 400;
    color: $black;
    margin-bottom: 2rem;
  }

  &-wrap {
    margin-bottom: 2.5rem;
    display: flex;
    gap: 1.25rem;
  }

  &-item {
    width: 100%;

    &__name {
      margin-bottom: 0.5rem;

      .required {
        color: $red;
      }
    }

    &__input {
      width: 100%;
      height: 2.2rem;
      padding: 0 0.8rem;
    }

    &__text {
      margin-top: 0.5rem;
      font-size: 0.7rem;
      line-height: 200%;
    }

    &__copy {
      position: relative;
      display: flex;
      align-items: center;

      img,
      svg {
        position: absolute;
        cursor: pointer;
        right: 0.8rem;
        width: 1.2rem;
      }
    }
  }

  &-btn {
    width: 10.6rem;
  }

  &-img {
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
    object-fit: cover;
  }
}

//____________PAYMENT_________

.payment {
  &-list {
    max-width: 53rem;
    display: flex;
    gap: 1.25rem;
    padding-top: 3rem;
  }

  &-item {
    width: 100%;
    height: 5rem;
    border-radius: 1rem;
    color: #fff;
    display: flex;
    align-items: center;
    padding-left: 1.2rem;
    padding-right: 0.8rem;
    font-weight: 500;
    font-size: 0.7rem;
    line-height: 145%;

    img {
      width: 4.75rem;
      margin-right: 1.2rem;
    }

    &.click {
      background: linear-gradient(82.81deg, #007bff -1.08%, #00deff 100%);
    }

    &.payme {
      background: linear-gradient(199.17deg, #00cccc 72.7%, #3399ff 139.51%);
    }

    &.paynet {
      background: #00b427;
    }
  }

  &-info {
    padding-top: 3rem;

    &__head {
      @include spaced();
      margin-bottom: 1.25rem;
    }

    &__wrap {
      display: flex;
      align-items: center;

      .btn {
        margin-left: 1rem;
      }
    }

    &__title {
      font-weight: 500;
      font-size: 1rem;
      color: $black;
    }

    .history-table {
      &__head {
        font-size: 0.7rem;

        & > div {
          &:nth-child(1) {
            width: 13%;
          }

          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(7),
          &:nth-child(8),
          &:nth-child(10) {
            width: 10%;
          }

          &:nth-child(9) {
            width: 7%;
          }
        }
      }

      &__row {
        & > div {
          &:nth-child(1) {
            width: 13%;
          }

          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6),
          &:nth-child(7),
          &:nth-child(8),
          &:nth-child(10) {
            width: 10%;
          }

          &:nth-child(9) {
            width: 7%;
          }
        }
      }
    }
  }
}

.rates {
  position: relative;

  &-list {
    display: flex;
    gap: 1.5rem;
    padding-top: 3rem;
  }

  &-item {
    width: 20%;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    padding: 1rem;
    @include trans();
    box-shadow: 1.997px 1.997px 13.083px 2.996px rgba(0, 0, 0, 0.08);

    &__start {
      .rates-item {
        &__name {
          color: #9bc4ac;

          &::after {
            background: #9bc4ac;
          }
        }

        &__coin {
          color: #9bc4ac;
        }

        &__info div::before {
          background: #9bc4ac;
        }
      }
    }

    &__bronze {
      .rates-item {
        &__name {
          color: #d3a076;

          &::after {
            background: #d3a076;
          }
        }

        &__coin {
          color: #d3a076;
        }

        &__info div::before {
          background: #d3a076;
        }
      }
    }

    &__silver {
      .rates-item {
        &__name {
          color: #aaa;

          &::after {
            background: #aaa;
          }
        }

        &__coin {
          color: #aaa;
        }

        &__info div::before {
          background: #aaa;
        }
      }
    }

    &__gold {
      .rates-item {
        &__name {
          color: #cdcd20;

          &::after {
            background: #cdcd20;
          }
        }

        &__coin {
          color: #cdcd20;
        }

        &__info div::before {
          background: #cdcd20;
        }
      }
    }

    &__special {
      .rates-item {
        &__name {
          color: #1f4f51;

          &::after {
            background: #1f4f51;
          }
        }

        &__coin {
          color: #1f4f51;
        }

        &__info div::before {
          background: #1f4f51;
        }
      }
    }

    &__name {
      font-size: 1.7rem;
      font-weight: 600;
      margin-bottom: 1.3rem;
      @include trans();
      position: relative;

      &::after {
        @include absoluteCenterX();
        bottom: -0.8rem;
        width: 70%;
        height: 0.1rem;
        content: '';
      }
    }

    &__coin {
      font-size: 1rem;
      color: #727272;
      margin-bottom: 1.7rem;
      @include trans();
      text-align: center;

      span {
        transition: 0.4s color;
        font-size: 1.6rem;
        display: block;
      }
    }

    &__info {
      font-size: 0.8rem;
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 0.85rem;
      margin-bottom: 1.25rem;
      @include trans();

      div {
        display: flex;
        align-items: center;
        gap: 0.3rem;

        &::before {
          width: 0.35rem;
          height: 0.35rem;
          background: #000;
          content: '';
          transform: rotate(45deg);
        }
      }

      p {
        font-size: 0.6rem;
      }
    }

    &__price {
      font-weight: 700;
      text-align: center;
      line-height: 170%;
      @include trans();
      color: $black;
      height: 4rem;

      span {
        font-size: 1.6rem;
        line-height: 90%;
        margin: 0 0.3rem;
      }
    }

    .btn {
      width: 100%;
      border-radius: 3rem;
      font-size: .8rem;
    }
  }

  .page-bg__footer {
    margin-top: 3rem;

    &::before {
      content: none;
    }
  }

  .page-bg__advice {
    color: #000;
  }
}

.calc {
  &-card {
    margin-top: 4.5rem;
    background: #fff;
    padding: 2rem;
    border-radius: 1rem;
    color: $black;
  }

  &-content {
    margin: 0 auto 2.5rem;
    text-align: center;
    max-width: 28rem;
  }

  &__title {
    font-size: 1.6rem;
    margin-bottom: 1rem;
    font-weight: 600;
  }

  &__text {
    font-size: 0.8rem;
    line-height: 150%;
  }

  &-wrap {
    @include spaced();
    gap: 2rem;
  }

  &-input {
    width: 55%;

    &__coin {
      font-size: .8rem;
    }

    &__input {
      position: relative;
      margin-bottom: 1rem;
      font-weight: 400;

      input {
        width: 100%;
      }
    }

    &__values {
      justify-content: space-between;
      display: flex;
      color: #9bc4ac;

      .active {
        color: $darkGreen;
      }
    }
  }

  &-info {
    max-width: 45%;
    display: flex;
    gap: 2rem;
    font-size: 1rem;
    align-items: flex-end;

    &__price {
      display: flex;
      gap: 0.5rem;
      align-items: flex-end;

      span {
        font-size: 2rem;
        font-weight: 600;
      }

      .mln {
        display: none;
      }

      .som {
        margin-bottom: 0.3rem;
      }
    }

    .btn {
      border-radius: 3rem;
      width: 8rem;
      font-size: .8rem;
    }
  }
}

//_________COIN__________

.coin {
  &__title {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    color: $black;

    span {
      color: $gray;
    }
  }

  &-table {
    margin-bottom: 2rem;
    font-weight: 500;
  }

  &-info {
    max-width: 41rem;
  }
}

//____________HOMEWORK__________

.homework {
  &-main {
    padding-top: 2rem;
    width: 100%;
  }

  &-head {
    margin-bottom: 2rem;
    @include spaced();
    font-weight: 500;
  }

  &__title {
    font-size: 1.2rem;
    color: $darkGreen;
    margin-bottom: 1rem;
    span {
      color: #868991;
      font-weight: 500;
    }
  }

  &__link {
    color: $darkGreen;
    font-size: 0.7rem;

    &:hover {
      color: $darkBlue;
    }
  }

  &__text {
    margin-bottom: 0.75rem;
  }

  &__input {
    width: 100%;
    margin-bottom: 0.75rem;

    input {
      width: 100%;
      padding: 0.8rem 1rem;
    }
  }

  &-choose {
    display: flex;
  }

  &__check {
    margin-right: 3rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    input {
      appearance: none;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #fff;
      margin-right: 0.5rem;
      display: inline-flex;
      position: relative;
      border: 1px solid #fff;

      &::after {
        position: absolute;
        left: 3px;
        top: 3px;
        width: 12px;
        height: 12px;
        background: $darkGreen;
        border-radius: 50%;
        content: none;
      }

      &:checked {
        border-color: $darkGreen;

        &::after {
          content: '';
        }
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &-info {
    display: flex;
    margin: 1.5rem 0;
    flex-wrap: wrap;

    li {
      margin-right: 2.5rem;
      text-transform: uppercase;
      font-size: 1rem;
      span {
        color: $black;
        font-weight: 500;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-content {
    padding: 2.1rem 1.6rem 3rem;
    @include card();
    margin-bottom: 2.3rem;
    line-height: 190%;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      right: 0;
      top: 0;
      width: 3.3rem;
      height: 2.3rem;
      border-bottom-left-radius: 1rem;
      border: 0.15rem solid #f5f6fa;
      z-index: 2;
      pointer-events: none;
    }

    &::after {
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 3;
      pointer-events: none;
      border-width: 0 3.3rem 2.3rem 0;
      border-color: transparent #f5f6fa transparent transparent;
      content: '';
    }

    &__title {
      color: #636467;
      margin-bottom: 1.2rem;
      font-size: 1.2rem;
      font-weight: 500;
    }

    &__text {
      margin-bottom: 1.2rem;
    }

    &__img {
      margin-bottom: 1.2rem;

      img {
        display: block;
      }
    }

    &__list {
      margin-bottom: 0.2rem;
    }

    &__download {
      float: right;
      color: $darkGreen;
      font-weight: 500;
      @include centerInline();
      font-size: 0.7rem;

      svg {
        width: 1.2rem;
        height: 1.2rem;
        margin-left: 0.25rem;
      }

      &:hover {
        color: $darkBlue;
      }
    }
  }

  &-upload {
    display: flex;
    align-items: center;
    font-size: 0.9rem;

    div {
      width: 23rem;
      padding: 0.8rem 1.6rem;
      background: #fff;
      border-radius: 3rem 0 0 3rem;
    }

    span {
      padding: 0.8rem 1.6rem;
      border-radius: 0 3rem 3rem 0;
      background: #fff;
      @include centerInline();
      color: $orange;
      cursor: pointer;
    }

    input {
      display: none;
    }

    .btn {
      margin-left: 1.5rem;
      color: $darkGreen;
    }
  }

  &-list {
    .ck-content {
      .table {
        max-width: 100%;
      }
    }
    &__item {
      margin-bottom: 2.5rem;
      display: flex;
    }

    &__wrap {
      width: 15rem;
      padding-right: 1rem;
    }

    &__title {
      color: $black;
      font-size: 1rem;
      margin-bottom: 1.25rem;
    }

    &__name {
      color: $black;
      margin-bottom: 1.4rem;
    }
  }

  &-other {
    &__item {
      display: flex;
      margin-bottom: 1.5rem;
      color: $black;
      font-size: 0.7rem;
      line-height: 120%;
    }

    .homework__title {
      width: 15rem;
      padding-right: 1rem;
      flex-shrink: 0;
    }
  }

  &-wrap {
    display: flex;
    flex-direction: column-reverse;
    .ck-content .table {
      max-width: 100%;
    }
  }

  &-side {
    flex-shrink: 0;
    //margin-left: 1.2rem;
    //width: 14.6rem;
    width: 100%;
    margin-top: 1rem;

    &__head {
      @include spaced();
      margin-bottom: 1rem;
    }

    &__name {
      color: $black;
    }

    &__add {
      margin-left: 0.75rem;
      width: 1.4rem;
      height: 1.4rem;
      @include centerInline();
      border-radius: 50%;
      background: $darkGreen;
      cursor: pointer;

      svg {
        width: 0.6rem;
      }

      &:hover {
        background: $darkBlue;
      }
    }
  }

  &-reviews {
    position: relative;
    display: flex;
    justify-content: flex-start;
    max-width: 100%;
    padding-bottom: 0.5rem;
    gap: 1.2rem;
  }

  &-card {
    @include card();
    border-radius: 0.5rem;
    padding: 1.2rem;
    font-size: 0.7rem;
    width: 31%;

    &__head {
      display: flex;
      align-items: center;
      margin-bottom: 1.7rem;
      color: $black;
      line-height: 121%;

      img {
        width: 1.3rem;
        height: 1.3rem;
        object-fit: cover;
        border-radius: 50%;
        flex-shrink: 0;
        margin-right: 0.5rem;
      }
    }

    &__text {
      line-height: 180%;
      color: $black;
    }
  }

  &-row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.5rem;
  }

  &-complete {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
    border-radius: 0.5rem;
    background-color: transparent;
    padding: 0.3rem 0.6rem;
    transition: 0.3s all;
    border: 1px solid $darkGreen;
    span {
      display: block;
      color: $darkGreen;
      font-size: 0.8rem;
      font-weight: 600;
    }
    svg {
      display: block;
      width: 100%;
      max-width: 1.2rem;
      height: auto;
    }
    &:hover {
      background-color: $darkGreen;
      span {
        color: #ffffff;
      }
      svg {
        path {
          stroke: #ffffff;
        }
      }
    }
  }
}

//____________LIVE_________

.live {
  &-main {
    padding-top: 3rem;
  }

  &-head {
    display: flex;
    font-size: 1rem;
    font-weight: 500;
    color: $lightGreen;
    margin-bottom: 2rem;

    li {
      margin-right: 2rem;
      cursor: pointer;

      &.current {
        color: $darkGreen;
        font-weight: 600;
      }
    }
  }

  &-item {
    margin-bottom: 1.5rem;
    @include card();
    display: flex;
    align-items: center;
    height: 14.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    &__img {
      height: 100%;
      width: 19rem;
      flex-shrink: 0;
      margin-right: 0.6rem;
      padding: 0.25rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        border-radius: 0.6rem;
      }
    }

    &__wrap {
      padding: 1.2rem;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
    }

    &__main {
      width: 100%;
    }

    &__head {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5rem;
    }

    &__cat {
      text-transform: uppercase;
      font-size: 0.7rem;
      font-weight: 500;
      color: $darkGreen;
    }

    &__tags {
      display: flex;

      li {
        margin-right: 0.5rem;
        color: #fff;
        background: $darkGreen;
        @include centerInline();
        padding: 0.25rem 0.8rem;
        border-radius: 2rem;
        font-weight: 500;
        font-size: 0.7rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__name {
      font-size: 1rem;
      color: $black;
      font-weight: 500;
      margin-bottom: 0.25rem;
    }

    &__author {
      font-size: 0.6rem;
      margin-bottom: 1.3rem;
    }

    &__desc {
      font-weight: 300;
      line-height: 1.4rem;
      font-size: 0.7rem;
      @include ellipsis();
      -webkit-line-clamp: 2;
      height: 2.8rem;
    }

    &__btn {
      //width: 7rem;
    }
  }

  &-video {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(4px);
    @include center();
    display: none;

    &__content {
      width: 85%;
      height: 85%;
      @include card();
      padding: 2.1rem;
    }

    &__iframe {
      height: calc(100% - 8rem);
      margin-bottom: 2rem;
    }

    &__name {
      font-size: 1.2rem;
      font-weight: 500;
      color: $black;
      margin-bottom: 0.8rem;
    }
  }
}

//___________LIBRARY________

.library {
  &-list {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;

    .lesson {
      width: calc(25% - 1.125rem);
    }
  }
}

//_____________CALENDAR__________

.calendar {
  &-main {
    padding-top: 3rem;
  }

  &-head {
    @include spaced();

    &__wrap {
      display: flex;
      align-items: center;
      font-weight: 600;
    }

    &__date {
      margin-right: 4rem;
      color: $black;
    }

    &__today {
      margin-right: 2.5rem;
      color: $darkGreen;
    }
  }

  &-search {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    margin-bottom: 2rem;
    gap: 0.75rem;
  }

  &-input {
    width: 11rem;
    flex-direction: row;
    position: relative;
    input {
      flex: 1;
      padding: 0.6rem;
    }

    img {
      @include absoluteCenterY();
      right: 1rem;
      width: 1.2rem;
    }
    svg {
      @include absoluteCenterY();
      right: 1rem;
      width: 1.2rem;
    }
  }

  &-title {
    margin-bottom: 1.2rem;
    font-size: 1.2rem;
    color: $black;
  }
}

//_____________CLASSES__________

.classes {
  &-head {
    display: flex;
    font-size: 1rem;
    font-weight: 500;
    color: $black;
    margin-bottom: 2.5rem;

    li {
      margin-right: 2rem;
      cursor: pointer;

      &.current {
        font-weight: 600;
        color: $darkGreen;
      }
    }
  }

  &__title {
    font-size: 1.2rem;
    margin-bottom: 1.2rem;
    font-weight: 500;
    color: $black;
  }

  &-main {
    &__incomplete {
      .history-table {
        &__head {
          & > div {
            width: 12%;

            &:first-child {
              width: 16%;
            }

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
              width: 20%;
            }
          }
        }

        &__row {
          & > div {
            width: 12%;

            &:first-child {
              width: 16%;
            }

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
              width: 20%;
            }
          }
        }
      }
    }

    &__complete {
      .btn {
        padding: 0.6rem 1rem;
      }

      .btn-white {
        margin-left: 0.5rem;
      }

      .history-table {
        &__row {
          & > div {
            width: 20%;

            &:nth-child(4) {
              width: 16%;
            }

            &:nth-child(5) {
              width: 24%;
            }
          }
        }
      }
    }
  }

  &-single {
    padding-top: 2rem;

    &__top {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 1rem;
      flex-wrap: wrap;
      margin-bottom: 1rem;
    }

    &__title {
      font-size: 1.4rem;
      color: $black;
      //margin-bottom: 1rem;
      span {
        color: #1f4f51;
      }
    }
    &__date {
      font-size: 1rem;
      color: #fb9851;
      font-weight: 600;
      span {
        color: $darkGreen;
      }
    }
  }

  &-coming {
    &__arrows {
      @include spaced();
      margin-bottom: 1.6rem;
    }

    &__prev,
    &__next {
      display: flex;
      align-items: center;

      div {
        width: 1.6rem;
        height: 1.6rem;
        @include centerInline();
        border-radius: 50%;
        background: $darkGreen;
        cursor: pointer;
        user-select: none;

        svg {
          width: 1.2rem;
          height: 1.2rem;
        }

        &:hover {
          background: $darkBlue;
        }
      }

      span {
        font-weight: 600;
        color: $darkGreen;
        font-size: 0.7rem;
        margin: 0 0.75rem;
      }
    }

    &__date {
      color: $black;
      font-weight: 500;

      span {
        font-weight: 600;
        color: $darkGreen;
        font-size: 0.7rem;
        margin: 0 0.75rem;
      }
    }

    .btn-white {
      margin-left: 0.5rem;
    }

    .history-table {
      &__row {
        & > div {
          width: 40%;

          &:nth-child(2) {
            width: 40%;
          }

          &:nth-child(3) {
            width: 20%;
          }
        }
      }
      &__programm {
        a {
          display: block;
          margin: 0.2rem 0;
          color: #fb9851;
          transition: 0.3s all;
          &:hover {
            color: #1a3b3d;
          }
        }
      }
    }
  }

  &-big {
    &__name {
      margin-bottom: 1.5rem;
      font-size: 1.2rem;
      color: $black;

      span {
        color: $gray;
        font-size: 1rem;
        margin-left: 1.2rem;
      }
    }

    &__card {
      @include card();
      padding: 2.1rem 1.2rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      color: $black;
      font-weight: 500;
      margin-bottom: 2.5rem;
      &-content {
        width: 75%;
      }
    }

    &__wrap {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      margin-bottom: 0.5rem;
    }

    &__teacher {
      align-items: flex-start;
      &-name {
        font-size: 1rem;
        line-height: 120%;
        color: #fb9851;
        width: 30%;
      }
      &-desc {
        font-size: 0.7rem;
        line-height: 120%;
        width: 40%;
      }
    }

    &__date {
      width: 20%;
    }

    &__desc {
      font-weight: 400;
      margin-bottom: 1.6rem;
      line-height: 156%;
    }

    &__btns {
      display: flex;
      justify-content: flex-end;
      gap: 0.75rem;
    }
  }

  .homework {
    &-main {
      padding-top: 0;
    }

    &__title {
      font-size: 1rem;
      font-weight: 400;
    }

    &-info {
      margin-top: 1.25rem;

      li {
        width: 15rem;
        font-size: 1.2rem;
        color: $black;
        margin-right: 0;
        margin-bottom: 1rem;

        span {
          display: block;
          font-size: 1rem;
          margin-top: 0.5rem;
        }
      }
    }
  }
}

//____________STUDENTS_________

.students {
  &-head {
    padding-top: 2rem;
    @include spaced();
    margin-bottom: 2.5rem;
  }

  &-info {
    display: flex;
    align-items: center;
    @include card();
    padding: 1rem 1.2rem;
    min-width: 14rem;

    &__photo {
      width: 2.3rem;
      height: 2.3rem;
      flex-shrink: 0;
      margin-right: 0.8rem;

      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
        border-radius: 50%;
      }
    }

    &__wrap {
      div {
        font-size: 1rem;
        color: $black;
      }

      p {
        font-size: 0.6rem;
      }
    }
  }

  &-menu {
    display: flex;
    align-items: center;

    li {
      margin-right: 2.1rem;
      color: $black;
      font-weight: 500;
      font-size: 1rem;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      &.current {
        color: $darkGreen;
        font-weight: 600;
      }
    }
  }

  &__title {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 2rem;
    color: $black;
  }

  &-basic {
    color: $black;

    &__list {
      li {
        display: flex;
        align-items: flex-start;
        margin-bottom: 1.6rem;
      }
    }

    &__name {
      width: 7.8rem;
      flex-shrink: 0;
      padding-right: 1rem;
      font-weight: 600;
    }

    &__table {
      td,
      th {
        padding-right: 2rem;
        padding-bottom: 1.25rem;
        min-width: 10rem;
        vertical-align: top;
      }

      th {
        font-weight: 400;
        color: $gray;
        font-size: 0.7rem;
      }
    }
  }

  &-class,
  &-reservation {
    .history-table {
      &__head {
        align-items: flex-end;

        & > div {
          &:nth-child(1) {
            width: 20%;
          }

          &:nth-child(2) {
            width: 20%;
          }

          &:nth-child(3) {
            width: 20%;
          }

          &:nth-child(4) {
            width: 40%;
          }
        }
      }

      &__row {
        & > div {
          &:nth-child(1) {
            width: 20%;
          }

          &:nth-child(2) {
            width: 20%;
          }

          &:nth-child(3) {
            width: 20%;
          }

          &:nth-child(4) {
            width: 40%;
          }
        }
      }
    }
  }

  &-notes {
    &__list {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 1rem;
      margin-bottom: 2rem;
    }
    &__item {
      position: relative;
      display: block;
      width: 100%;
      max-width: 49%;
      background-color: #fff;
      border-radius: 1rem;
    }

    &__title {
      width: 100%;
      padding: 1rem;
      background-color: $darkGreen;
      border-radius: 1rem 1rem 0 0;
      font-weight: 500;
      font-size: 1rem;
      color: #ffffff;
    }

    &__info {
      padding: 1rem 2rem;
      max-width: 31rem;

      li {
        margin-bottom: 0.75rem;
        line-height: 150%;

        span {
          font-weight: 500;
          color: $black;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.modal {
  &-video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 50%;
    video {
      display: block;
      width: auto;
      max-width: 100%;
      margin: 0 auto;
      height: 70vh;
    }
  }
}
//_____________MEDIA QUERY__________

@media (max-width: 2800.98px) {
  :root {
    font-size: 26px;
  }
}

@media (max-width: 2600.98px) {
  :root {
    font-size: 24px;
  }
}

@media (max-width: 2300.98px) {
  :root {
    font-size: 22px;
  }
}

@media (max-width: 2100.98px) {
  :root {
    font-size: 20px;
  }
}

@media (max-width: 1800.98px) {
  :root {
    font-size: 19px;
  }
}

@media (max-width: 1700.98px) {
  :root {
    font-size: 18px;
  }
}

@media (max-width: 1600.98px) {
  :root {
    font-size: 17px;
  }
}

@media (max-width: 1500.98px) {
  :root {
    font-size: 16px;
  }
}

@media (max-width: 1400.98px) {
  :root {
    font-size: 15px;
  }
}

@media (max-width: 1300.98px) {
  :root {
    font-size: 14px;
  }
}

@media (max-width: 1200.98px) {
  :root {
    font-size: 13px;
  }
}

@media (max-width: 1100.98px) {
  :root {
    font-size: 12px;
  }
}

@media (max-width: 1000.98px) {
  :root {
    font-size: 11px;
  }
}

@media (max-width: 900.98px) {
  :root {
    font-size: 10px;
  }
}

@media (max-width: 767.98px), (max-width: 1080px) and (orientation: portrait) {
  :root {
    font-size: 17px;
  }

  .login {
    &__logo img {
      width: 5rem;
    }
  }

  .sideinfo {
    position: absolute;
    width: 0;
  }

  .sidemenu {
    position: static;

    &__menu {
      display: none;
    }

    &__logo {
      margin-bottom: 0;
      flex-shrink: 0;
    }
  }

  .sidebar {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: $darkGreen;
    padding: 1rem 2rem;
    justify-content: space-between;
    z-index: 5;

    &-mobile {
      display: block;
      width: 3rem;
      height: 3rem;
      img,
      svg {
        display: block;
        width: 100%;
        max-width: 100%;
        height: auto;
      }
    }

    &-photo {
      display: block;

      img {
        width: 3.2rem;
        border-radius: 50%;
        border: 0.2rem solid rgba(255, 255, 255, 0.5);
      }
    }
  }

  .profile {
    &-info {
      margin-bottom: 0;
      padding: 0.8rem 1.5rem;
      width: 20rem;
      position: fixed;
      top: 4rem;
      right: 1rem;
      z-index: 5;
      display: none;
    }

    &-menu {
      display: none;
    }

    &-cards {
      display: none;
    }
  }

  body {
    padding: 7rem 2rem 2rem;
  }

  .mobile-menu {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: $darkGreen;
    z-index: 10;

    &__wrap {
      padding: 3rem;
    }

    &__head {
      @include spaced();
      margin-bottom: 4rem;
    }

    &__close {
      width: 3rem;
      svg {
        display: block;
        width: 100%;
        max-width: 100%;
        height: auto;
      }
    }

    &__logo img {
      width: 5rem;
    }

    &__close img {
      width: 2.5rem;
    }

    &__list {
      li {
        margin-bottom: 2.5rem;

        a {
          color: #fff;
          font-size: 1.5rem;
          @include spaced();
        }

        .profile-menu__wrap {
          display: flex;
          align-items: center;
        }

        svg {
          width: 2rem;
          height: 2rem;
          margin-right: 1rem;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .messages {
    height: calc(100vh - 20rem);
  }

  .history-table {
    max-width: 100%;
    overflow: auto;

    &__head,
    &__row {
      width: 60rem;
    }

    &::-webkit-scrollbar {
      height: 0.4rem;
    }
  }

  .filter {
    &__head {
      display: block;
    }

    &-gender {
      margin-top: 1rem;
    }

    &__main {
      flex-wrap: wrap;
      gap: 1rem;
    }
  }

  .teachers {
    &-item {
      flex-wrap: wrap;

      &__wrap {
        &:nth-child(2) {
          width: calc(100% - 12rem);
        }

        &:nth-child(3) {
          width: 100%;
        }
      }
    }

    &-single {
      &__wrap {
        display: block;
      }
    }

    &-info {
      margin-left: 0;
      margin-top: 2rem;
      width: 100%;
      display: flex;

      &__wrap {
        padding-top: 1rem;
      }

      .teachers-item__img {
        margin-bottom: 0;

        img {
          height: 100%;
        }
      }
    }
  }

  .course {
    &-list {
      gap: 1.5rem;
    }

    &-item {
      width: calc(50% - 0.75rem);
    }
  }

  .courses {
    &-cards {
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 1.5rem;
    }

    &-card {
      width: calc(50% - 0.75rem);
    }

    &-new {
      padding-bottom: 3rem;

      &__table {
        overflow: auto;

        &::-webkit-scrollbar {
          height: 0.4rem;
        }

        table {
          width: 60rem;
        }
      }
    }

    &-group {
      &__filter {
        display: block;
        margin-bottom: 0;
      }

      &__wrap {
        margin-bottom: 1rem;
      }

      &__right {
        width: 13rem;
      }

      &__price {
        flex-wrap: wrap;

        span {
          display: block;
          margin-left: 0;
        }
      }

      &__btn {
        width: 100%;
      }

      &-single {
        &__card {
          display: block;
          overflow: hidden;
        }

        &__wrap {
          width: 100%;
        }

        &__img {
          width: 100%;

          &::after {
            content: none;
          }
        }
      }
    }
  }

  .individual {
    &-wrap {
      display: block;

      .filter {
        width: 100%;
        margin-right: 0;
        display: flex;
        flex-wrap: wrap;

        &__head {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
        }

        &-courses {
          width: 100%;
        }

        &-gender {
          width: 15rem;
          margin-top: 1.5rem;
        }

        &__main {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          flex-direction: row;

          & > div {
            width: 48%;
          }
        }

        &-days .filter-wrap {
          display: inline-flex;
        }
      }
    }
  }

  .settings {
    &-form__item {
      width: 100%;
      margin-bottom: 2.5rem;
    }
  }

  .live-video {
    &__content {
      width: 95%;
      height: 50%;
    }
  }

  .library {
    &-list {
      .lesson {
        width: calc(33.3333% - 1rem);
      }
    }
  }

  .calendar {
    &-search {
      flex-wrap: wrap;
      justify-content: flex-start;

      .calendar-input {
        width: calc(33.333% - 0.5rem);
      }
    }
  }

  .classes {
    .homework-info {
      gap: 1rem;

      li {
        width: calc(33.3333% - 0.6666rem);
      }
    }
  }

  .rates {
    &-list {
      flex-wrap: wrap;
      justify-content: center;
    }

    &-item {
      width: calc(50% - 0.75rem);
    }
  }

  .calc {
    &-wrap {
      flex-direction: column;
    }

    &-input {
      width: 100%;
    }

    &-info {
      width: 100%;
      max-width: none;
    }
  }

  .homework {
    &-wrap {
      display: block;
    }

    &-side {
      margin-left: 0;
    }
  }

  .students {
    &-head {
      display: block;
    }

    &-info {
      display: inline-flex;
      margin-bottom: 2rem;
    }
  }
}

@media (max-width: 767.98px) {
  :root {
    font-size: 18px;
  }

  .mobile-menu {
    &__wrap {
      padding: 1.5rem 1rem;
      padding-top: 0.8rem;
    }

    &__logo img {
      width: 3rem;
    }

    &__close img {
      width: 2rem;
    }

    &__head {
      margin-bottom: 1.5rem;
    }

    &__list {
      li {
        margin-bottom: 1.5rem;

        a {
          font-size: 1.1rem;
        }

        svg {
          width: 1.7rem;
          height: 1.7rem;
          margin-right: 0.5rem;
        }
      }
    }
  }

  .login {
    &::-webkit-scrollbar {
      display: none;
    }

    &__logo img {
      width: 4rem;
    }

    &-form {
      width: 95vw;
      padding: 1.5rem 1rem;
    }
  }

  .sidebar {
    padding: 0.5rem 1rem;

    &-mobile img {
      width: 2rem;
    }

    &-photo img {
      width: 2.2rem;
    }
  }

  .sidemenu__logo {
    width: 3rem;
  }

  .profile-info {
    padding: 0.7rem;
    top: 3rem;
    width: 18rem;

    &__balance {
      svg {
        width: 1rem;
        margin-right: 0.2rem;
      }

      font-size: 0.7rem;
    }
  }

  .search {
    margin-right: 1rem;

    &-input__input {
      width: calc(100vw - 9rem);
    }
  }

  .header {
    flex-direction: column-reverse;

    &-nav {
      margin-bottom: 1.5rem;
    }
  }

  body {
    padding: 5.5rem 1rem 2.5rem;
  }

  .btn {
    padding: 0.6rem 1rem;
  }

  .home {
    &-recent {
      &__head {
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
      }

      &__carousel .lesson__img {
        height: auto;
      }

      &__wrap {
        justify-content: space-between;
        width: 100%;
      }
    }

    &-welcome {
      padding: 1.5rem 1.5rem 0;
      overflow: hidden;

      &__content {
        max-width: 100%;
      }

      &__img {
        position: static;
        width: calc(100% + 3rem);
        height: auto;
        margin: 2rem -1.5rem 0;
      }
    }
  }

  .lesson {
    &__img {
      height: 13rem;
    }
    &-enter {
      &__content {
        width: 95vw;
        padding: 1rem;
      }
    }
  }

  .messages {
    height: calc(100vh - 13rem);
    &-list {
      height: calc(100vh - 19rem);
    }
    &-wrap {
      padding: 0;
    }

    &-list li {
      padding: 1rem;
      margin-bottom: 1rem;
      overflow: hidden;
    }

    &-nots {
      &__item {
        display: block;
      }

      &__img {
        width: 100%;
        height: auto;
        margin: 0;
        margin-top: 1rem;
      }
    }
  }

  .reviews {
    &-list {
      padding-top: 0;

      &__item {
        padding: 1rem 0.5rem;
        display: block;
      }

      &__teacher {
        margin-top: 2rem;
        justify-content: flex-end;
      }
    }

    &-single {
      padding-top: 0;

      &__head {
        margin-bottom: 2rem;
      }

      &__main {
        padding: 1.5rem;
        overflow: hidden;
      }

      &__bot {
        flex-direction: column-reverse;
        align-items: flex-end;
      }
    }

    &-add {
      &__choose {
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
      }

      &__item {
        width: calc(50% - 0.5rem);
      }
    }
  }

  .filter {
    padding-top: 0;

    &__wrap {
      display: block;
    }

    &__title {
      margin-right: 0;
      margin-bottom: 0.7rem;
    }
  }

  .teachers {
    &-item {
      padding: 1rem;
      gap: 1.5rem;

      &__img {
        width: 11rem;
      }

      &__btn {
        width: 11rem;
        display: flex;
      }

      &__wrap {
        &:nth-child(1) {
          width: 100%;
        }

        &:nth-child(2) {
          width: 100%;
        }

        &:nth-child(3) {
          width: 100%;
          max-width: 22rem;
          margin: 0 auto;
        }
      }
    }

    &-single {
      &__wrap {
        padding-top: 0;
      }
    }

    &-course {
      display: block;

      text-align: center;

      &__info {
        width: 100%;
        padding: 0 1rem 1rem;
        border-right: 0;
        border-bottom: 1px solid #f5f6fa;
      }

      &__calendar {
        width: 100%;
        padding: 0 1rem;
      }
    }

    &-info {
      display: block;
      padding: 0;

      &__wrap {
        padding-top: 1rem;
      }

      .teachers-item__img {
        margin-bottom: 0;
        width: 100%;

        img {
          height: 17rem;
        }
      }
    }
  }

  .course {
    &-list {
      gap: 1rem;
      padding-top: 0;
    }

    &-item {
      width: 100%;
      padding: 1.2rem;
    }
  }

  .courses {
    &-single {
      &__head {
        padding-top: 0;
      }

      &__wrap {
        display: block;
      }

      &__content {
        width: 100%;
        margin-bottom: 2rem;
      }
    }

    &-cards {
      gap: 1rem;
    }

    &-card {
      width: 100%;
    }

    &-new {
      padding: 0 0 1rem;
    }

    &-group {
      &__filter {
        padding-top: 0;
      }

      &__wrap {
        flex-wrap: wrap;
      }

      &__title {
        margin-bottom: 1rem;
        margin-right: 0;
      }

      &__item {
        display: block;
      }

      &__img {
        width: 100%;
        margin-right: 0;
      }

      &__main {
        padding: 1.2rem 1.6rem 1.6rem;
        border-right: 0;
        border-bottom: 0.2rem solid #f5f6fa;
      }

      &__right {
        width: 100%;
      }

      &__price span {
        margin-left: 0.4rem;
      }

      &-single {
        &__wrap {
          padding: 1rem;
        }

        &__title {
          font-size: 1.4rem;
          margin-bottom: -1rem;
        }
        &__container {
          .card {
            width: 100%;
            max-width: 100%;
          }
        }

        &__numbers {
          flex-wrap: wrap;
          gap: 1rem;
          li {
            width: 47%;
            margin-right: 0;
          }
        }
      }
    }

    &-waiting {
      padding-top: 0;
      margin-bottom: 2rem;
    }
  }

  .individual {
    &-wrap {
      padding-top: 0;

      .filter {
        &__head {
          .filter__wrap {
            width: 100%;
          }
        }

        &-gender {
          width: 100%;
          margin-top: 0;
        }

        &__main {
          gap: 0;

          & > div {
            width: 100%;
          }
        }

        &-days .filter-wrap {
          display: inline-flex;
        }
      }
    }

    &-item {
      width: 100%;
      padding: 1rem;
    }

    &-mask {
      width: 90%;
    }
    &-popup {
      &__content {
        width: 100%;
      }
    }
  }

  .support {
    &-content {
      margin-bottom: 2rem;
    }
  }

  .settings {
    &-form {
      padding-top: 0;
    }

    &-wrap {
      gap: 1rem;
      flex-wrap: wrap;
    }
  }

  .payment {
    &-info {
      &__head {
        display: block;
      }

      &__wrap {
        margin-bottom: 0.5rem;
      }
    }

    &-list {
      padding-top: 0;
      display: block;
    }

    &-item {
      margin-bottom: 1rem;
    }
  }

  .rates {
    &-list {
      padding-top: 1rem;
    }

    &-item {
      width: 70%;
    }
  }

  .calc {
    &-card {
      padding: 2rem 1rem;
      margin-top: 2rem;
    }

    &-content {
      margin-bottom: 1.5rem;
    }

    &-input {
      font-size: 0.8rem;

      &__input {
        &::after {
          right: -0.9rem;
        }
      }
    }

    &-info {
      font-size: 1rem;
      gap: 1rem;
      flex-direction: column;
      align-items: center;

      &__price {
        span {
          font-size: 2rem;
        }

        .som {
          margin-bottom: 0.2rem;
        }
      }

      &__coin {
        margin-bottom: 0.2rem;
      }
    }
  }

  .coin-table table td {
    padding: 1rem;
  }

  .calendar {
    &-head {
      display: block;

      &__wrap {
        justify-content: space-between;
      }
    }

    &-main {
      padding-top: 0;
    }

    &-search {
      .calendar-input,
      .btn {
        width: calc(50% - 0.375rem);
      }
    }
  }

  .homework {
    &-main {
      padding: 0;
    }

    &-head {
      display: block;
    }

    &__title {
      margin-bottom: 0.5rem;
    }

    &-content {
      padding: 2.5rem 1.2rem 3rem;
    }

    &-upload {
      .btn {
        margin-left: 0.5rem;
        flex-shrink: 0;
      }
    }

    &-other {
      &__item {
        display: block;
      }

      .homework__title {
        width: 100%;
        padding-right: 0;
        margin-bottom: 1rem;
      }
    }
  }

  .live {
    &-main {
      padding-top: 0;
    }

    &-item {
      display: block;
      height: auto;

      &__img {
        width: 100%;
        height: 17rem;
      }

      &__btn {
        margin-top: 2rem;
      }
    }

    &-video {
      &__content {
        padding: 1rem;
      }
    }
  }

  .library {
    &-list {
      gap: 1rem;

      .lesson {
        width: calc(50% - 0.5rem);
      }
    }
  }

  .classes {
    &-main__complete .btn-white {
      margin-left: 0.5rem;
    }

    &-big {
      &__card {
        padding: 1.5rem 1rem;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      &__wrap {
        width: 100%;
        margin-top: 1rem;
      }
    }

    &-head {
      margin-bottom: 2rem;
    }

    &-single {
      padding-top: 0;
    }
  }

  .breadcrumbs {
    margin: 0 0 2rem;
  }

  .students {
    &-head {
      padding-top: 0;
    }

    &-menu {
      position: relative;
      max-width: 100%;
      overflow-x: auto;
      justify-content: space-between;
      padding-bottom: 1rem;
      gap: 1rem;

      li {
        font-size: 0.8rem;
        margin: 0;
        white-space: nowrap;
      }
    }

    &-basic {
      &__program {
        display: block !important;
      }

      &__table {
        margin-top: 1rem;

        td,
        th {
          padding-right: 1rem;
          min-width: 0;
          padding-bottom: 1rem;
        }
      }
    }

    &-notes {
      &__list {
      }
      &__item {
        max-width: 100%;
        padding-top: 5.5rem;
      }
    }
  }

  .modal {
    &-video {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      max-width: 90%;
      video {
        width: 100%;
        height: 90vh;
      }
    }
  }
}

@media (max-width: 375.98px) {
  :root {
    font-size: 14px;
  }
}

@media (max-width: 340.98px) {
  :root {
    font-size: 13px;
  }
}

@media (max-width: 320.98px) {
  :root {
    font-size: 12px;
  }
}

@media (max-width: 300.98px) {
  :root {
    font-size: 11px;
  }
}

//___________MOBILE-LANDSCAPE________

@media (max-width: 1000px) and (orientation: landscape) {
  :root {
    font-size: 8.5px;
  }
}

@media (max-width: 700px) and (orientation: landscape) {
  :root {
    font-size: 8px;
  }
}
// Izzat
// Small Calendar
.teachers-item__wrap .Calendar {
  width: auto !important;
}
.redDay:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  color: #f53744;
  background: rgba(245, 55, 68, 0.1);
}

.greenDay:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
  color: #08b84e !important;
  background: rgba(8, 184, 78, 0.1) !important;
}

.Calendar {
  box-shadow: none !important;
}
abbr[title] {
  border-bottom: none;
}
// .Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)
.Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween) {
  font-weight: normal !important;
  color: rgba(0, 0, 0, 0.8) !important;
}
.Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after {
  height: 0 !important;
}

// BigCalendar
.rbc-calendar {
  min-height: 600px;
}
#my-tooltip {
  z-index: 1000;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;

  .page-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    margin: 0 5px;
    font-size: 16px;
    text-decoration: none;
    color: #1f4f51;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background-color: #1f4f51;
      color: #fff;
    }
  }

  .page-item {
    display: flex;
    justify-content: center;
    align-items: center;

    .page-link {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 35px;
      height: 35px;
      font-size: 16px;
      text-decoration: none;
      color: #1f4f51;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 4px;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background-color: #1f4f51;
        color: #fff;
      }
    }
  }

  .arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    font-size: 20px;
    font-weight: bold;
    color: #1f4f51;
    border: 1px solid #ddd;
    background-color: #fff;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      background-color: #1f4f51;
      color: #fff;
    }
  }

  .current .page-link {
    background-color: #1f4f51;
    color: #fff;
    font-weight: bold;
    border-color: #1f4f51;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.5;
    background-color: transparent !important;

    .page-link {
      cursor: not-allowed;
      color: #ccc;
      background-color: #f9f9f9;
      border-color: #ddd;
    }
  }
}
