.toast-container {
  max-width: 28rem; /* max-w-md */
  width: 100%; /* w-full */
  background-color: #ffffff; /* bg-white */
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.05); /* shadow-lg */
  border-radius: 0.5rem; /* rounded-lg */
  pointer-events: auto;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.05); /* ring-1 ring-black ring-opacity-5 */

  &.animate-enter {
    animation: fadeIn 0.2s ease-in-out;
  }

  &.animate-leave {
    animation: fadeOut 0.2s ease-in-out;
  }
}

.toast-content {
  flex: 1;
  width: 0; /* flex-1 w-0 */
  padding: 1rem; /* p-4 */

  .toast-header {
    display: flex;
    align-items: start;

    .toast-image {
      flex-shrink: 0; /* flex-shrink-0 */
      padding-top: 0.125rem; /* pt-0.5 */

      img {
        height: 2.5rem; /* h-10 */
        width: 2.5rem; /* w-10 */
        border-radius: 50%; /* rounded-full */
      }
    }

    .toast-text {
      margin-left: 0.75rem; /* ml-3 */
      flex: 1;

      .toast-name {
        font-size: 0.875rem; /* text-sm */
        font-weight: 500; /* font-medium */
        color: #1f2937; /* text-gray-900 */
      }

      .toast-message {
        margin-top: 0.25rem; /* mt-1 */
        font-size: 0.875rem; /* text-sm */
        color: #6b7280; /* text-gray-500 */
      }
    }
  }
}

.toast-close {
  display: flex;
  border-left: 1px solid #e5e7eb; /* border-l border-gray-200 */

  button {
    width: 100%; /* w-full */
    border: none; /* border border-transparent */
    border-top-right-radius: 0.5rem; /* rounded-r-lg */
    padding: 1rem; /* p-4 */
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.875rem; /* text-sm */
    font-weight: 500; /* font-medium */
    color: #4f46e5; /* text-indigo-600 */
    cursor: pointer;
    transition: color 0.2s;

    &:hover {
      color: #4338ca; /* hover:text-indigo-500 */
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px #4f46e5; /* focus:ring-2 focus:ring-indigo-500 */
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(1rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(1rem);
  }
}
