//___________COLORS____________

$blue: #527ae7;
$darkGreen: #1f4f51;
$gray: #83878f;
$black: #202125;
$pink: #ff1477;
$orange: #fb9851;
$darkBlue: #00347e;
$red: #ff1414;
$green: #09b74e;
$lightGreen: #9bc4ac;

:root {
  --rt-opacity: 1!important;
}
// ______ SCHEDULE _________

.myCustomHeight {
  height: 40rem;
  margin-top: 1rem;
}

.rbc {
  &-month-header {
    .rbc-header {
      border: none;
      margin-bottom: 0.5rem;
      text-align: left;
      padding-left: 0.5rem;
      font-size: 0.8rem;
      font-weight: 400;
      color: $gray;
    }

    .rbc-header + .rbc-header {
      border: none;
    }
  }

  &-month-view {
    border: none !important;
  }

  &-month-row {
    margin-bottom: 0.5rem;

    .rbc-row-bg {
      gap: 0.5rem;
      &>div {
        overflow: hidden;
      }
    }

    .rbc-day-bg {
      background: #fff;
      border-radius: 0.5rem;
    }

    .rbc-day-bg + .rbc-day-bg {
      border: none;
    }

    & + .rbc-month-row {
      border: none !important;
    }

    .rbc-date-cell {
      padding: 0.5rem 1.2rem;
      font-size: 1.1rem;
      line-height: 100%;
      color: $black;

      &.rbc-off-range {
        opacity: 0.5;
      }
    }

    .rbc-row-segment {
      padding: 0 0.5rem;
    }

    .rbc-show-more {
      color: $gray;
      font-weight: 400;
    }
  }

  &-overlay {
    border-radius: 0.5rem;
    z-index: 999!important;
  }

  &-selected {
    background: $green;
  }


  &-event,
  &-day-slot,
  &-background-event {
    //background: $darkGreen !important;
    background: #E9F4F5 !important;
    margin-bottom: 0.2rem !important;
  }

  &-event {
    //margin-bottom: 5px!important;
    &-content {
      color: #023C40;
    }
  }
}

.Calendar__monthSelectorItem.-active .Calendar__monthSelectorItemText,
.Calendar__yearSelectorItem.-active .Calendar__yearSelectorText {
  background-color: $darkGreen !important;
}

.Calendar__section {
  justify-content: center;
}

.Calendar__monthSelectorAnimationWrapper {
  display: flex;
}

.Calendar__weekDays {
  justify-content: flex-start !important;
}

.Calendar__weekDay {
  width: 2rem !important;
  padding: 0 !important;
}

.Calendar__day.-ltr {
  width: 2rem;
  height: 2rem;
  min-height: 0 !important;
  font-size: 0.7rem !important;
}

@media (max-width: 767.98px), (max-width: 1080px) and (orientation: portrait) {
  .myCustomHeight {
    height: 33rem;
  }
}

@media (max-width: 767.98px) {
  .calendar-wrap {
    width: calc(100% + 2rem);
    overflow-x: auto;
    height: auto;
    margin-left: -1rem;
    padding: 0 1rem 7rem;

    &::-webkit-scrollbar {
      height: 0.5rem;
    }
  }

  .myCustomHeight {
    width: 70rem;
  }

  .Calendar__day.-ltr {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 0.75rem !important;
  }
}
// Test Izzat code
.moreLessonItem-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.moreLessonItem-title {
  font-size: 0.7rem;
}
.rbc-overlay {
  min-width: 500px !important;
  top: 983px;
  left: 769.281px;
}
.moreLessonItem .content {
  max-height: 0;
  overflow: hidden;
}
.moreLessonItem .content.show {
  height: auto;
  max-height: 7000px;
}
